import "react-toastify/dist/ReactToastify.css";

import { Modal } from "@powerledger/ui-component-lib";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Registry, useRegistriesQuery } from "@/app/types/generated/graphql";

import { RegistryForm } from "./registry-form";

export const RegistryModal: FC<{
  visible: boolean;
  closeModal: () => void;
}> = ({ visible, closeModal }) => {
  const { t } = useTranslation();
  const [selectedRegistry, setSelectedRegistry] = useState<Registry | null>();

  const { data: registriesData } = useRegistriesQuery();

  const registryOptions = useMemo(() => registriesData?.registries || [], [registriesData?.registries]);

  const modalTitle = useMemo(() => {
    if (!selectedRegistry) return t("Select a registry to link");
    return t("Enter your {{registryName}} registry ID", {
      registryName: registryOptions?.find((ro) => ro?.id === selectedRegistry.id)?.shortName || "",
    });
  }, [registryOptions, selectedRegistry, t]);

  return (
    <Modal visible={visible} maskClosable onCancel={closeModal} sx={{ maxHeight: "inherit" }} title={modalTitle}>
      <RegistryForm
        closeModal={closeModal}
        selectedRegistry={selectedRegistry}
        setSelectedRegistry={setSelectedRegistry}
      />
    </Modal>
  );
};
