import { useTablePaginationManager } from "@powerledger/ui-component-lib";
import { subYears } from "date-fns";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ForwardTradeWithdrawCustomProps } from "@/app/components/forward-trade-withdraw-modal/forward-trade-withdraw-modal.types";
import { defaultFilter, mapStackedFilterStateToFilterState } from "@/app/components/page-header";
import { FilterState } from "@/app/components/page-header/filter/filter.types";
import { useHasPermissions } from "@/app/hooks/use-has-permission";
import { UserFeatures } from "@/app/hooks/use-okta-profile-features";
import { useRecOptions } from "@/app/hooks/use-rec-options";
import { getDatePart } from "@/app/lib/date-helpers";
import { formatAttributes, ReturnTypeFormatAttributes } from "@/app/lib/format-attributes";
import { ModalContext } from "@/app/modal-provider/modal-context";
import {
  SortOrderInput,
  TradeAttributes,
  TradeContract,
  TradeContractStatus,
  useTradeContractHistoryQuery,
} from "@/app/types/generated/graphql";

import { getForwardTradeColumns } from "./forward-trade-columns";
import {
  AssignRecsProps,
  PartialAccountInfo,
  TradeContractHistoryTableData,
  UseForwardTradeHistoryFn,
} from "./forward-trade-history.types";

export const useForwardTradeHistory: UseForwardTradeHistoryFn = () => {
  const { hasFeaturePermission } = useHasPermissions({ skipQuery: true });
  const [showAllAttributes, setShowAllAttributes] = useState(false);
  const { t } = useTranslation();
  const [activeFilters, setActiveFilters] = useState<FilterState>(defaultFilter);
  const { showForwardTradeRecsAssignModal, showForwardTradeRecsWithdrawModal } = useContext(ModalContext);

  const { pageInfo, offset, fetchData, setTotalItems, sort, resetPage } = useTablePaginationManager<
    TradeContract,
    SortOrderInput[]
  >({});

  const { recOptions, loading: recOptionsLoading } = useRecOptions();

  const { data, loading, refetch } = useTradeContractHistoryQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        paginationInput: {
          offset,
          limit: pageInfo.pageSize,
        },
        sortOrderInputs: sort,
        where: {
          recAttributes: {
            vintages: activeFilters.vintages,
            eligibilities: activeFilters.eligibilities,
            locations: activeFilters.locations,
            fuelSources: activeFilters.fuelSources,
            commencementDateGte: activeFilters.cod?.length
              ? getDatePart(subYears(new Date(), +activeFilters.cod?.[0]))
              : undefined,
            certifications: activeFilters.certifications,
          },
          statuses: activeFilters.statuses as TradeContractStatus[],
          tradeSettlementDate: activeFilters.selectedDate[0],
        },
      },
    },
    onCompleted(data) {
      setTotalItems(data.tradeContractHistory.offsetInfo.total ?? 0);
    },
  });

  //added new attribute types on "formatAttributes"
  const tableData = useMemo(() => {
    const tradeContractHistoryTableData: TradeContractHistoryTableData[] = [];

    if (!data?.tradeContractHistory.tradeContracts) return tradeContractHistoryTableData;
    for (const tradeContract of data.tradeContractHistory.tradeContracts) {
      const attributes = tradeContract.attributes as TradeAttributes;
      const account = tradeContract.account as PartialAccountInfo;
      const formattedAttributes = recOptions
        ? formatAttributes({
            attributes,
            position: tradeContract?.position,
            options: recOptions,
          })
        : ({} as ReturnTypeFormatAttributes);
      tradeContractHistoryTableData.push({
        ...formattedAttributes,
        ...tradeContract,
        attributes,
        account,
      });
    }
    return tradeContractHistoryTableData;
  }, [recOptions, data?.tradeContractHistory.tradeContracts]);

  const reloadTradeContractHistory = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleAssignRecs = useCallback(
    ({
      tradeContractNumber,
      tradeContractId,
      askTradeVolume,
      registryCode,
      tradeAttributes,
      status,
    }: AssignRecsProps) => {
      showForwardTradeRecsAssignModal({
        tradeContractNumber,
        tradeContractId,
        askTradeVolume,
        registryCode,
        tradeAttributes,
        status,
      });
    },
    [showForwardTradeRecsAssignModal],
  );

  const handleWithdrawRecs = useCallback(
    ({ tradeID }: ForwardTradeWithdrawCustomProps) => {
      showForwardTradeRecsWithdrawModal({
        tradeID,
      });
    },
    [showForwardTradeRecsWithdrawModal],
  );

  const tableColumns = useMemo(
    () => getForwardTradeColumns(t, handleAssignRecs, handleWithdrawRecs),
    [t, handleAssignRecs, handleWithdrawRecs],
  );

  const handleFilterChange = useCallback(
    (arg) => {
      setActiveFilters((activeFilters) => ({ ...activeFilters, ...mapStackedFilterStateToFilterState(arg) }));
      resetPage();
    },
    [resetPage],
  );

  return {
    forwardTradeEnabled: hasFeaturePermission([UserFeatures.FORWARD_TRADE_ENABLED]),
    tableData,
    loading: loading || recOptionsLoading,
    fetchData,
    pageInfo,
    reloadTradeContractHistory,
    tableColumns,
    showAllAttributes,
    setShowAllAttributes,
    handleFilterChange,
  };
};
