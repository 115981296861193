import { CRSSmallSVG, Flex, ThemeUIStyleObject } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { CERTIFICATIONS, CRS, ELIGIBILITIES } from "@/app/lib/format-attributes";
import { OrderPosition, TradePosition } from "@/app/types/generated/graphql";

import { attributesMapper } from "../forward-trade-attributes-tooltip/forward-trade-attributes-mapper";
import { TableValueWrapper } from "../table-value-wrapper";

export const certificationIconMap = (certification = "") => {
  if (certification.includes(CRS)) {
    return (
      <Flex sx={{ padding: 1, alignItems: "center" }}>
        <CRSSmallSVG />
      </Flex>
    );
  }
  return null;
};

export const EligibilityValueFormatter = ({
  certificationsAndEligibilities = [],
  position,
  sx,
  showAllEligibility = false,
}: {
  position: OrderPosition | TradePosition;
  sx?: ThemeUIStyleObject;
  certificationsAndEligibilities: { label: string; value: string[] }[];
  showAllEligibility?: boolean;
}) => {
  const { t } = useTranslation();
  const certifications =
    certificationsAndEligibilities.find((certEligibility) => certEligibility.label === CERTIFICATIONS)?.value ?? [];
  const eligibilities =
    certificationsAndEligibilities.find((certEligibility) => certEligibility.label === ELIGIBILITIES)?.value ?? [];

  const eligibilityToShow = [...certifications, ...eligibilities];

  const emptyEligibility = position === OrderPosition.Ask ? "-" : "Any";

  const eligibilityValue = eligibilityToShow.filter(Boolean).length ? eligibilityToShow : emptyEligibility;

  return showAllEligibility ? (
    <>{attributesMapper(t, eligibilityValue)}</>
  ) : (
    <Flex
      sx={{
        alignItems: "center",
      }}
    >
      {certificationIconMap(eligibilityToShow[0])}
      <TableValueWrapper
        value={eligibilityValue}
        title="Eligibilities"
        containerSx={{
          minWidth: 100,
          ...sx,
        }}
      />
    </Flex>
  );
};
