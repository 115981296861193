import currency from "currency.js";
import { lowerCase } from "lodash";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import { notifications } from "@/app/container/notifications";
import { useTransactionFee } from "@/app/hooks/use-transaction-fee";
import { ModalContext } from "@/app/modal-provider/modal-context";
import {
  Offer,
  OfferAction,
  OfferGroupsDocument,
  OfferStatus,
  OrderPosition,
  useCounterOffersInOfferGroupQuery,
  useFewAccountDetailsQuery,
  useReviewOfferMutation,
} from "@/app/types/generated/graphql";

import { CounteredOrdersData } from "../countered-orders";
import {
  ActiveReviewOfferProps,
  CounterOffersProps,
  CounterOffersViewProps,
  OfferActions,
  ReviewOfferModalDescription,
} from "./counter-offers.types";

export const useCounterOffers = ({ offerGroupNumber }: CounterOffersProps): CounterOffersViewProps => {
  const { t } = useTranslation();
  const location = useLocation();
  const { showCreateCounterOfferModal } = useContext(ModalContext);

  const counteredRECS: CounteredOrdersData = location.state?.selectedRecs;

  const [showOfferReviewModal, setShowOfferReviewModal] = useState(false);
  const [offerNumber, setOfferNumber] = useState("");
  const [currentActionType, setCurrentActionType] = useState<OfferAction>(OfferAction.Withdraw);
  const [activeReviewOffer, setActiveReviewOffer] = useState<ActiveReviewOfferProps>();

  const { data, loading } = useCounterOffersInOfferGroupQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filter: {
        offerGroupNumber,
      },
    },
    skip: !offerGroupNumber,
  });

  const { data: accountData } = useFewAccountDetailsQuery();

  const { getAppliedFee } = useTransactionFee();

  const fee = getAppliedFee(OrderPosition.Bid);

  const canTakeOfferAction = (offerStatus: OfferStatus, offerFromId: string, action: OfferActions): boolean => {
    if (offerStatus !== OfferStatus.Requested) return false;
    if (action === OfferActions.WITHDRAW) return accountData?.account?.id === offerFromId;
    if (action === OfferActions.PROCESS) return accountData?.account?.id !== offerFromId;
    return false;
  };

  const initReviewOffer = (reviewAction: OfferAction, reviewOffer: Offer) => {
    setCurrentActionType(reviewAction);
    setOfferNumber(reviewOffer.offerNumber);
    setActiveReviewOffer({
      unitPrice: currency(reviewOffer.unitPrice, { fromCents: true }).value,
      volume: reviewOffer.volume,
      fee: fee,
      actionType: reviewAction,
    });
    setShowOfferReviewModal(true);
  };

  const reviewOfferModalTitle = `Are you sure you want to ${lowerCase(currentActionType)} this offer?`;

  const reviewOfferModalDescription = ReviewOfferModalDescription[currentActionType];

  const [reviewOffer, { loading: reviewOfferLoading }] = useReviewOfferMutation({
    refetchQueries: [OfferGroupsDocument],
  });

  const onReviewConfirm = useCallback(async () => {
    try {
      const { data } = await reviewOffer({
        variables: {
          input: {
            action: currentActionType,
            offerNumber,
          },
        },
      });
      if (data?.reviewOffer) {
        setShowOfferReviewModal(false);
        notifications.info({ description: t("Your offer is currently being processed."), duration: 3000 });
      }
    } catch (error) {
      setShowOfferReviewModal(false);
      error instanceof Error &&
        notifications.error({
          description: t(error.message),
        });
    }
  }, [reviewOffer, t, currentActionType, offerNumber]);

  const initCreateCounterOffer = useCallback(
    (counteredOffer: Offer) => {
      showCreateCounterOfferModal({
        counteredTableData: {
          ...counteredRECS,
          accountId: counteredOffer.fromAccountId,
          price: {
            ...counteredRECS.price,
            amount: counteredOffer.unitPrice.toString(),
          },
        },
        counteredVolume: counteredOffer.volume,
        tenantID: counteredOffer.tenantId,
        counterFromID: accountData?.account?.id,
        offerNumber: counteredOffer.offerNumber,
        offerGroupNumber: counteredOffer.offerGroupNumber,
      });
    },
    [showCreateCounterOfferModal, counteredRECS, accountData],
  );

  return {
    offers: data?.offers ?? [],
    loading,
    canTakeOfferAction,
    showOfferReviewModal,
    initReviewOffer,
    reviewOfferModalTitle,
    setShowOfferReviewModal,
    onReviewConfirm,
    reviewOfferLoading,
    reviewOfferModalDescription,
    initCreateCounterOffer,
    activeReviewOffer,
  };
};
