import { DatePicker, Option } from "@powerledger/ui-component-lib";
import { subYears } from "date-fns";
import { isEqual, startCase } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";

import {
  getLocationOptions,
  getNewStateWithAllOrNoneForAKey,
  getVintageOptions,
  MultiSelectActions,
} from "@/app/components/local-selects/helpers";
import { useGetLocationTexts } from "@/app/hooks/use-get-locations-text";
import { useRecOptions } from "@/app/hooks/use-rec-options";
import { getDatePart } from "@/app/lib/date-helpers";
import { APOLLO_GRAPH_VARIANT } from "@/app/lib/env-helpers";
import { CERTIFICATIONS, ELIGIBILITIES } from "@/app/lib/format-attributes";
import { AppDateFormats, formatDate } from "@/app/lib/format-date";
import { getTextToShowOnValue } from "@/app/lib/order-helpers";
import { OrderStatus, TradeContractStatus } from "@/app/types/generated/graphql";

import { VintageOptionComponent } from "../../local-selects/common-components";
import {
  FilterKeys,
  FilterState,
  FiltersType,
  FilterValue,
  StackedFilterState,
  StackedValues,
  StackedValueState,
  UseFilter,
} from "./filter.types";

export const defaultFilter = {
  vintages: [],
  locations: [],
  eligibilities: [],
  fuelSources: [],
  projects: [],
  certifications: [],
  statuses: [],
  cod: [],
  dateRange: [],
  actionCodes: [],
  selectedDate: [],
};

const removeKey = (val: { value: string; key: Date }[]) => val.map((val) => val.value);

export const generateObjFromFilterState = (...state: StackedFilterState[]) => {
  const newObj = Object.fromEntries(Object.keys(state[0]).map((key) => [key, {}])) as Record<
    FilterKeys,
    Record<string, FilterValue>
  >;
  for (const key in state[0]) {
    const filterKey = key as FilterKeys;
    for (const val of state[0][filterKey]) {
      if (!newObj[filterKey][val.value]) {
        newObj[filterKey] = { ...(newObj[filterKey] ?? {}), [val.value]: val };
      }
    }
  }
  for (const key in state[1]) {
    const filterKey = key as FilterKeys;
    for (const val of state[1][filterKey]) {
      if (!newObj[filterKey][val.value]) {
        newObj[filterKey] = { ...(newObj[filterKey] ?? {}), [val.value]: val };
      }
    }
  }
  return newObj;
};

export const getFieldDisabled = (
  type: FilterKeys,
  filterValues: StackedFilterState,
  initialValues: StackedFilterState,
) => {
  switch (type) {
    case "vintages":
      return !!initialValues.vintages.length;
    case "locations":
      return (
        !!initialValues.eligibilities.length || !!initialValues.locations.length || !!filterValues.eligibilities?.length
      );
    case "eligibilities":
      return (
        !!(initialValues.eligibilities.length && initialValues.certifications.length) ||
        (!!initialValues.certifications.length && !!(filterValues.locations.length || initialValues.locations.length))
      );
    case "fuelSources":
      return !!initialValues.fuelSources.length;
    case "projects":
      return !!initialValues.projects.length;
    case "cod":
      return !!initialValues.cod.length;
    case "statuses":
      return !!initialValues.statuses.length;
    default:
      return false;
  }
};

export const handleFilterChange = (
  args: Partial<FilterState>,
  setter: React.Dispatch<React.SetStateAction<StackedFilterState>>,
) => {
  setter((filter) => {
    const partialState = { ...filter };
    for (const key in args) {
      const filterKey = key as FilterKeys;
      const newValues =
        args[filterKey]
          ?.filter((val) => filter[filterKey].findIndex((value) => value.value == val) < 0)
          ?.map((value) => ({
            value,
            key: new Date(),
          })) ?? [];
      const removedValues =
        filter[filterKey].filter((val) => !args[filterKey]?.includes(val.value))?.map((val) => val.value) ?? [];

      partialState[filterKey] = partialState[filterKey].filter((val) => !removedValues.includes(val.value));
      partialState[filterKey] = partialState[filterKey].concat(newValues);
    }
    return partialState;
  });
};

export const mapStackedFilterStateToFilterState = (args: StackedFilterState) =>
  Object.fromEntries(Object.entries(args).map(([key, value]) => [key, removeKey(value)])) as FilterState;

/**
 * For testing environments, we want more COD options because of the nature data in testing environments
 */
export const getCommencementOperationDateOptions = (
  environment: "development" | "staging" | "sandbox" | "production",
) =>
  Array.from({ length: environment === "production" ? 10 : 25 }, (_, i) => ({
    label: `Less than ${i + 1} year${i === 0 ? "" : "s"} old`,
    value: `${i + 1}`,
  }));

export const useFilter: UseFilter = (args) => {
  const {
    shouldSelectVintage = false,
    hideStatusFilter = false,
    initialValues = defaultFilter,
    filterFormFieldProps = {},
    applyFilters = () => {},
    customValues,
    customSetter,
    datePickerProps,
    hideProjectFilter = false,
  } = args ?? {};
  const { recOptions } = useRecOptions();
  const { t } = useTranslation();
  const [filterValues, setFilterValues] = useState<StackedFilterState>(initialValues);
  const currentFilterValues = customValues ?? filterValues;
  /**
   * State to check if the previously applied value is same as current value
   * if they are same, disable the apply button for better UX
   * Better to handle this here than passing the props from parent component which could cause to write unnecessary memoizations due to nested children
   */
  const [prevValues, setPrevValues] = useState<StackedFilterState>(initialValues);

  const { getLocationTextsWithCountry } = useGetLocationTexts({ options: recOptions });

  const setValues = useMemo(() => customSetter ?? setFilterValues, [customSetter]);

  const eligibilityFilter = (recOptions?.certificationOptions ?? [])
    .map((opt) => ({ key: CERTIFICATIONS, ...opt }))
    .concat((recOptions?.eligibilityOptions ?? []).map((opt) => ({ key: ELIGIBILITIES, ...opt })));

  const getLabelForValues = useCallback(
    (key: FilterKeys, values: string[]): string[] => {
      switch (key) {
        case "vintages":
          return getTextToShowOnValue(values);
        case "locations":
          return getLocationTextsWithCountry(values);
        case "eligibilities":
          return values.map((value) => recOptions?.eligibilityOptions?.find((val) => value === val.value)?.label ?? "");
        case "certifications":
          return values.map(
            (value) => recOptions?.certificationOptions?.find((val) => value === val.value)?.label ?? "",
          );
        case "fuelSources":
          return values.map((value) => recOptions?.fuelSourceOptions?.find((val) => value === val.value)?.label ?? "");
        case "projects":
          return values.map((value) => recOptions?.projectOptions?.find((val) => value === val.value)?.label ?? "");
        case "cod":
          return values.map((value) => `COD: less than ${value} year${value === "1" ? "" : "s"} old`);
        case "dateRange":
          return values.length > 1 ? [`From ${values[0]} to ${values[1]}`] : [];
        default:
          return values;
      }
    },
    [
      getLocationTextsWithCountry,
      recOptions?.eligibilityOptions,
      recOptions?.certificationOptions,
      recOptions?.fuelSourceOptions,
      recOptions?.projectOptions,
    ],
  );

  const handleChange = (args: Partial<FilterState>) => {
    handleFilterChange(args, setValues);
  };

  const stackedValues = useMemo(() => {
    /**
     * Convert array values into obj (for improving complexity)
     */

    const currentFilterObj = generateObjFromFilterState(currentFilterValues);
    const prevFilterObj = generateObjFromFilterState(prevValues);
    const combinedUniqueFilterObj = generateObjFromFilterState(currentFilterValues, prevValues);
    return (
      Object.keys(currentFilterValues)
        .map((filterType) => {
          const filterKey = filterType as FilterKeys;
          /**
           * for each key in combined filter (unique)
           * get unique values
           */
          const uniqueSet = Object.values(combinedUniqueFilterObj[filterKey]);
          /**
           * get labels with other stuffs for each value
           */
          const values = getLabelForValues(
            filterKey,
            uniqueSet.map((val) => val.value),
          ).map((label, i) => {
            const hasValueInPrevValues = !!prevFilterObj[filterKey][uniqueSet[i].value];
            const hasValueInCurrentValues = !!currentFilterObj[filterKey][uniqueSet[i].value];

            return {
              label,
              key: uniqueSet[i].key,
              type: filterKey,
              value: uniqueSet[i].value,
              state:
                hasValueInPrevValues && !hasValueInCurrentValues
                  ? StackedValueState.DELETING
                  : !hasValueInPrevValues && hasValueInCurrentValues
                  ? StackedValueState.ADDING
                  : StackedValueState.ADDED,
            };
          });
          return values;
        })
        .flat(1)
        /** When the time value is same
         * when removing the field, since we remove it from filterValues,
         * the orientation gets distorted
         * so to have the orientation static, sort it by label as well then time
         */
        .sort((a, b) => (a.label > b.label ? 1 : -1))
        .sort((a, b) => a.key.getTime() - b.key.getTime())
    );
  }, [currentFilterValues, getLabelForValues, prevValues]);

  const filters: FiltersType = args.overrideFilters ?? [
    {
      name: "Vintage",
      keys: "vintages",
      isRequired: shouldSelectVintage,
      onChange: (options) => {
        if (Array.isArray(options)) {
          handleChange({ vintages: options.map((opt) => opt.value) });
          return;
        }

        const singleOption = options as SingleValue<Option>;
        if (singleOption) {
          handleChange({ vintages: [singleOption.value] });
        }
      },
      options: getVintageOptions(recOptions),
      disabled: getFieldDisabled("vintages", filterValues, initialValues),
      values: removeKey(filterValues.vintages),
      components: {
        GroupHeading: () => null,
        Option: VintageOptionComponent,
      },
      isMulti: true,
      ...filterFormFieldProps.vintages,
    },
    {
      name: "Eligibility",
      keys: ["eligibilities", "certifications"],
      options: eligibilityFilter,
      values: removeKey([...filterValues.eligibilities, ...filterValues.certifications]),
      disabled: getFieldDisabled("eligibilities", filterValues, initialValues),
      onChange: (options) => {
        if (Array.isArray(options)) {
          handleChange({
            eligibilities: options.filter((opt) => opt.key === ELIGIBILITIES).map((opt) => opt.value),
            certifications: options.filter((opt) => opt.key === CERTIFICATIONS).map((opt) => opt.value),
          });
          return;
        }

        const singleOption = options as SingleValue<Option>;
        if (singleOption) {
          handleChange({
            eligibilities: singleOption.key === ELIGIBILITIES ? [singleOption.value] : [],
            certifications: singleOption.key === CERTIFICATIONS ? [singleOption.value] : [],
          });
        }
      },
      disableOptions: (key) =>
        Boolean(key === ELIGIBILITIES && (initialValues.locations.length || filterValues.locations.length)),
      isMulti: true,
      ...filterFormFieldProps.eligibilities,
    },
    {
      name: "Location",
      keys: "locations",
      options: getLocationOptions({
        options: recOptions?.locationOptions ?? [],
        values:
          recOptions?.locationOptions?.flatMap(
            (o) => o.options?.filter((so) => !!filterValues.locations?.find((val) => val.value === so.value)) ?? [],
          ) ?? [],
        allSelectedLabel: t("All"),
      }),
      values: removeKey(filterValues.locations),
      onChange: (options) => {
        if (Array.isArray(options)) {
          const deselectAll = options.find((opt) => opt.value === MultiSelectActions.AllDeselected);
          const selectAll = options.find((opt) => opt.value === MultiSelectActions.AllSelected);
          let newState = options.map((o) => o.value);

          const currValues = filterValues.locations;
          if (selectAll) {
            newState = getNewStateWithAllOrNoneForAKey(
              removeKey(currValues),
              recOptions?.locationOptions ?? [],
              true,
              selectAll.key,
            );
          } else if (deselectAll) {
            newState = getNewStateWithAllOrNoneForAKey(
              removeKey(currValues),
              recOptions?.locationOptions ?? [],
              false,
              deselectAll.key,
            );
          }
          handleChange({ locations: newState });
          return;
        }

        const singleOption = options as SingleValue<Option>;
        if (singleOption && !Object.values(MultiSelectActions).includes(singleOption.value)) {
          handleChange({
            locations: [singleOption.value],
          });
        }
      },
      disabled: getFieldDisabled("locations", filterValues, initialValues),
      isMulti: true,
      ...filterFormFieldProps.locations,
    },
    {
      name: "Fuel Type",
      keys: "fuelSources",
      options: recOptions?.fuelSourceOptions ?? [],
      values: removeKey(filterValues.fuelSources),
      disabled: getFieldDisabled("fuelSources", filterValues, initialValues),
      onChange: (options) => {
        if (Array.isArray(options)) {
          handleChange({ fuelSources: options.map((opt) => opt.value) });
          return;
        }

        const singleOption = options as SingleValue<Option>;
        if (singleOption) {
          handleChange({ fuelSources: [singleOption.value] });
        }
      },
      isMulti: true,
      ...filterFormFieldProps.fuelSources,
    },
    ...((!hideProjectFilter
      ? [
          {
            name: "Project",
            keys: "projects",
            options: recOptions?.projectOptions ?? [],
            values: removeKey(filterValues.projects),
            disabled: getFieldDisabled("projects", filterValues, initialValues),
            onChange: (options) => {
              if (Array.isArray(options)) {
                handleChange({
                  projects: options.map((opt) => opt.value),
                });
                return;
              }

              const singleOption = options as SingleValue<Option>;
              if (singleOption) {
                handleChange({
                  projects: [singleOption.value],
                });
              }
            },
            isMulti: true,
            ...filterFormFieldProps.projects,
          },
        ]
      : []) satisfies FiltersType),
    {
      name: "COD",
      keys: "cod",
      options: getCommencementOperationDateOptions(APOLLO_GRAPH_VARIANT),
      values: removeKey(filterValues.cod)[0],
      disabled: getFieldDisabled("cod", filterValues, initialValues),
      onChange: (option) => {
        if (Array.isArray(option)) {
          return;
        }

        const singleOption = option as SingleValue<Option>;
        if (singleOption) {
          handleChange({
            cod: [singleOption.value],
          });
        }
      },
      header: filterValues.cod.length ? (
        <>
          {t(`RECs that are from projects no older than {{date}} will be shown.`, {
            date: getDatePart(subYears(new Date(), +filterValues.cod?.[0]?.value)),
          })}
        </>
      ) : null,
      isMulti: false,
      info: filterFormFieldProps.cod?.dynamicInfo?.(filterValues.cod),
      ...filterFormFieldProps.cod,
    },
    ...((!hideStatusFilter
      ? [
          {
            name: "Status",
            keys: "statuses",
            disabled: getFieldDisabled("statuses", filterValues, initialValues),
            onChange: (options) => {
              if (Array.isArray(options)) {
                handleChange({
                  statuses: options.map((opt) => opt.value),
                });
                return;
              }

              const singleOption = options as SingleValue<Option>;
              if (singleOption) {
                handleChange({
                  statuses: [singleOption.value],
                });
              }
            },
            options: Object.values(datePickerProps ? TradeContractStatus : OrderStatus).map((value) => ({
              value,
              label: startCase(value.toLowerCase()),
            })),
            values: removeKey(filterValues.statuses),
            isMulti: true,
            ...filterFormFieldProps.statuses,
          },
        ]
      : []) satisfies FiltersType),
    ...((datePickerProps
      ? [
          {
            name: datePickerProps.datePickerName,
            keys: datePickerProps.datePickerKey,
            options: [],
            isDropdown: false,
            render: () => (
              <DatePicker
                placeholder={t(datePickerProps.datePickerName)}
                onChange={(date) => {
                  const formattedDate = formatDate(date?.toString(), {
                    formatStyle: AppDateFormats.HyphenUniversalDateFormat,
                    noTZ: true,
                  });
                  handleChange({
                    selectedDate: [formattedDate.trim()],
                  });
                }}
                wrapperClassName="forwardDateContainer"
                popperClassName="forwardDatePopper"
              />
            ),
            isMulti: false,
            values: removeKey(filterValues.selectedDate)[0],
            onChange: () => {},
          },
        ]
      : []) satisfies FiltersType),
  ];

  const someValueFilled = Object.values(currentFilterValues).some((x) => x.length);

  const buttonDisabled = useMemo(() => {
    const currentValue = currentFilterValues;
    /**
     * IF previous value and current value is same, disable the button
     */

    if (isEqual(prevValues, currentValue)) return true;
    /**
     * If vintage should be selected,
     * check either if current value is totally empty (no filters applied i.e. reset)
     * or current value requires vintage if any other fields are selected
     */
    if (shouldSelectVintage) {
      /**
       * Check if current value is empty or not
       * if empty enable the button
       * else check vintages since vintage is a required field along side other filters
       */
      if (!someValueFilled) return false;
      return !currentValue.vintages.length;
    }

    /**
     * Check if dateRange has values -> this dateRange needs to have atleast two values for it to work, otherwise disable the button
     * dont disable the button in rest of the cases
     */
    return currentValue.dateRange?.length ? currentValue.dateRange?.length < 2 : false;
  }, [currentFilterValues, prevValues, shouldSelectVintage, someValueFilled]);

  const resetFilter = useCallback(() => {
    setValues(initialValues);
    applyFilters(initialValues);
    setPrevValues(initialValues);
  }, [initialValues, setValues, applyFilters]);

  const applyFilter = useCallback(() => {
    applyFilters(currentFilterValues);
    setPrevValues(currentFilterValues);
  }, [applyFilters, currentFilterValues]);

  const removeValue = useCallback(
    (type: FilterKeys, values: string | string[]) => {
      setValues((filter) => {
        const finalValue = type === "dateRange" ? [] : filter[type].filter((val) => !values.includes(val.value));
        return {
          ...filter,
          [type]: finalValue,
        };
      });
    },
    [setValues],
  );

  const addValue = useCallback(
    (type: FilterKeys, value: StackedValues["0"]) => {
      setValues((filter) => ({
        ...filter,
        [type]: [
          ...filter[type],
          {
            value: value.value,
            key: value.key,
          },
        ],
      }));
    },
    [setValues],
  );

  return {
    buttonDisabled,
    someValueFilled,
    filters,
    stackedValues,
    filterValues,
    applyFilter,
    resetFilter,
    removeValue,
    addValue,
    appliedFilterValues: prevValues,
  };
};
