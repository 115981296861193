import "../fonts.css";
import "react-toastify/dist/ReactToastify.css";
import "./global-style.css";

import { ApolloProvider } from "@apollo/client";
import OktaAuth from "@okta/okta-auth-js";
import { ConfigCatProvider, createFlagOverridesFromMap, OverrideBehaviour, PollingMode } from "configcat-react";
import { ReactElement } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { GenericTooltip, MobileRestrictedModal } from "./components";
import container from "./container";
import { CONFIGCAT_CACHE_TTL_DEFAULT } from "./hooks";
import {
  CONFIGCAT_CACHE_TTL,
  CONFIGCAT_OVERRIDES,
  CONFIGCAT_SDK_KEY,
  OKTA_CLIENT_ID,
  OKTA_ISSUER,
} from "./lib/env-helpers";
import { ModalProvider } from "./modal-provider";
import { AppRouter } from "./navigation/app-router";

const oktaAuth = new OktaAuth({
  issuer: OKTA_ISSUER,
  clientId: OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}/login/callback`,
  pkce: true,
});

const App = (): ReactElement => {
  return (
    <ConfigCatProvider
      sdkKey={CONFIGCAT_SDK_KEY}
      pollingMode={PollingMode.LazyLoad}
      options={{
        flagOverrides: createFlagOverridesFromMap(CONFIGCAT_OVERRIDES, OverrideBehaviour.LocalOverRemote),
        cacheTimeToLiveSeconds: Number(CONFIGCAT_CACHE_TTL) || CONFIGCAT_CACHE_TTL_DEFAULT,
      }}
    >
      <ApolloProvider client={container.apolloClient}>
        <ToastContainer />
        <BrowserRouter>
          <ModalProvider>
            <AppRouter oktaAuth={oktaAuth} />
          </ModalProvider>
        </BrowserRouter>
        <GenericTooltip />
        <MobileRestrictedModal />
      </ApolloProvider>
    </ConfigCatProvider>
  );
};

export default App;
