import { DEFAULT_FEE } from "@/app/lib/order-helpers";
import { AssetType, OrderPosition, TradePosition, useTransactionFeeQuery } from "@/app/types/generated/graphql";

export const useTransactionFee = () => {
  const { data, loading, error } = useTransactionFeeQuery({
    variables: {
      input: {
        assetType: AssetType.Rec,
      },
    },
  });

  const getAppliedFee = (orderPosition: OrderPosition | TradePosition) => {
    const fee =
      orderPosition == OrderPosition.Ask
        ? data?.transactionFee.sellerTxnFeePercent
        : data?.transactionFee.buyerTxnFeePercent;

    return fee ?? DEFAULT_FEE;
  };

  if (error) {
    throw error;
  }

  return {
    getAppliedFee,
    loading,
  };
};
