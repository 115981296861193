import { IconWarning, Link } from "@powerledger/ui-component-lib";
import { Trans } from "react-i18next";
import { Flex, Text } from "theme-ui";

export const ForwardTradeDisabledView: React.FC<{ supportEmail: string }> = ({ supportEmail }) => {
  return (
    <Flex
      sx={{ p: 3, display: "flex", flexDirection: "row", gap: 2, backgroundColor: "warning.100", alignItems: "center" }}
    >
      <IconWarning size={8} />

      <Flex
        sx={{
          color: "textDark",
          fontSize: 2,
          justifyContent: "center",
        }}
      >
        <Text>
          <Trans
            values={{
              supportEmail,
            }}
          >
            The Forward Trading feature is not enabled on your account. To activate this feature, please reach out to
            our support team at{" "}
            <Link
              href={`mailto:${supportEmail}`}
              sx={{
                color: "accent",
                textDecoration: "none",
                "&:hover": { textDecoration: "underline" },
                whiteSpace: "nowrap",
              }}
            >
              {{ supportEmail }}
            </Link>
            . We will assist you in getting started!
          </Trans>
        </Text>
      </Flex>
    </Flex>
  );
};
