import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { notifications } from "@/app/container/notifications";
import {
  TradeContractAction,
  TradeContractHistoryDocument,
  useReviewTradeContractMutation,
} from "@/app/types/generated/graphql";

export const useForwardTradeWithdrawModal = ({
  tradeID,
  closeModal,
}: {
  tradeID: string;
  closeModal: VoidFunction;
}) => {
  const { t } = useTranslation();
  const [reviewTradeContract, { loading }] = useReviewTradeContractMutation({
    refetchQueries: [TradeContractHistoryDocument],
  });

  const onWithdrawAction = useCallback(async () => {
    try {
      await reviewTradeContract({
        variables: {
          input: {
            action: TradeContractAction.Withdraw,
            id: tradeID,
          },
        },
      });
      notifications.success({ description: t("Trade successfully withdrawn") });
      closeModal();
    } catch (e) {
      e instanceof Error &&
        notifications.error({
          description: t(e?.message),
        });
    }
  }, [reviewTradeContract, tradeID, t, closeModal]);

  return {
    loading,
    onWithdrawAction,
    closeModal,
  };
};
