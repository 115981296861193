import { TradeContractStatus } from "@/app/types/generated/graphql";

export const canShowViewAssets = (status: TradeContractStatus) => {
  return [
    TradeContractStatus.AssetsLoaded,
    TradeContractStatus.PendingComplete,
    TradeContractStatus.Failed,
    TradeContractStatus.Completed,
  ].includes(status);
};
