import { useTablePaginationManager } from "@powerledger/ui-component-lib";
import { subYears } from "date-fns";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { defaultFilter, mapStackedFilterStateToFilterState } from "@/app/components/page-header";
import { FilterState } from "@/app/components/page-header/filter/filter.types";
import { useRecOptions } from "@/app/hooks";
import { useHasPermissions } from "@/app/hooks/use-has-permission";
import { UserFeatures } from "@/app/hooks/use-okta-profile-features";
import { getDatePart } from "@/app/lib/date-helpers";
import { formatAttributes, ReturnTypeFormatAttributes } from "@/app/lib/format-attributes";
import { ModalContext } from "@/app/modal-provider";
import {
  RecOrderAttributes,
  SortOrderInput,
  TradePosition,
  useTradeContractsQuery,
} from "@/app/types/generated/graphql";

import { ForwardTradeMarketTableData } from "../components";
import { UseForwardTradeFn } from "./forward-trade.types";
import { getForwardTradeMarketColumns } from "./forward-trade-market-columns";

export const useForwardTrade: UseForwardTradeFn = () => {
  const { t } = useTranslation();

  const { hasFeaturePermission } = useHasPermissions({ skipQuery: true });
  const forwardTradeEnabled = useMemo(
    () => hasFeaturePermission([UserFeatures.FORWARD_TRADE_ENABLED]),
    [hasFeaturePermission],
  );
  const [activeFilters, setActiveFilters] = useState<FilterState>(defaultFilter);
  const { showAcceptForwardTradeModal } = useContext(ModalContext);

  const { pageInfo, offset, fetchData, sort, setTotalItems, resetPage } = useTablePaginationManager<
    ForwardTradeMarketTableData,
    SortOrderInput[]
  >({});

  const { recOptions, accountData, loading: recOptionsLoading } = useRecOptions();

  const { data, loading, refetch } = useTradeContractsQuery({
    variables: {
      input: {
        paginationInput: {
          limit: pageInfo.pageSize ?? 20,
          offset,
        },
        sortOrderInputs: sort,
        where: {
          recAttributes: {
            vintages: activeFilters.vintages,
            eligibilities: activeFilters.eligibilities,
            locations: activeFilters.locations,
            fuelSources: activeFilters.fuelSources,
            commencementDateGte: activeFilters.cod?.length
              ? getDatePart(subYears(new Date(), +activeFilters.cod?.[0]))
              : undefined,
            certifications: activeFilters.certifications,
          },
          tradeSettlementDate: activeFilters.selectedDate[0],
        },
      },
    },
    skip: !forwardTradeEnabled,
    onCompleted(data) {
      setTotalItems(data?.tradeContractMarketQuery?.offsetInfo?.total ?? 0);
    },
  });

  //seperating market table columns for preventing maintainability issues : Maintainability rating C
  const tableColumns = useMemo(
    () => getForwardTradeMarketColumns(t, accountData, showAcceptForwardTradeModal),
    [t, accountData, showAcceptForwardTradeModal],
  );

  const tableData = useMemo(() => {
    const forwardTableData: ForwardTradeMarketTableData = [];

    const queryData = data?.tradeContractMarketQuery.tradeContracts;

    if (!queryData) return forwardTableData;

    for (const contract of queryData) {
      const formattedAttributes = recOptions
        ? formatAttributes({
            attributes: contract.attributes as RecOrderAttributes,
            /** For forward trades always display "Any" in case of attribute is not specified, regardless of Bid or Ask */
            position: TradePosition.Bid,
            options: recOptions,
          })
        : ({} as ReturnTypeFormatAttributes);

      forwardTableData.push({
        ...contract,
        formattedAttributes: {
          ...contract.attributes,
          vintages: formattedAttributes.vintages,
          eligibilities: formattedAttributes.eligibilities,
          certifications: formattedAttributes.certifications,
          fuelSources: formattedAttributes.fuelSources,
          locations: formattedAttributes.locations,
          projects: formattedAttributes.projects,
          certificationsAndEligibilities: formattedAttributes.certificationsAndEligibilities,
        },
      });
    }
    return forwardTableData;
  }, [data?.tradeContractMarketQuery.tradeContracts, recOptions]);

  const reloadForwardTrades = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleFilterChange = useCallback(
    (arg) => {
      setActiveFilters((activeFilters) => ({ ...activeFilters, ...mapStackedFilterStateToFilterState(arg) }));
      resetPage();
    },
    [resetPage],
  );

  return {
    forwardTradeEnabled,
    tableData,
    loading: loading || recOptionsLoading,
    reloadForwardTrades,
    pageInfo,
    fetchData,
    handleFilterChange,
    tableColumns,
  };
};
