import { Button, EmailSVG, Flex, IconLoading, IconLongArrow } from "@powerledger/ui-component-lib";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";

import { ErrorMessage, Form } from "@/app/components/form";
import { Input } from "@/app/components/input";

import { PublicLayout } from "../public-layout";

export const ForgotPasswordEmailForm = ({ onSuccess }: { onSuccess: (email: string) => void }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PublicLayout
      heading={t("Forgot your password? It happens.")}
      subheading={t("Forgotten Password")}
      action={{
        text: t("Back to Login"),
        onClick: () => {
          navigate("/");
        },
      }}
    >
      <Formik
        initialValues={{ email: "" }}
        validationSchema={object().shape({
          email: string().required(t("Email is required")).email(t("Please provide a valid email address")),
        })}
        onSubmit={async ({ email }) => await onSuccess(email)}
        validateOnMount
      >
        {({ handleSubmit, isSubmitting, touched, setFieldTouched, isValid, setFieldValue, values }) => (
          <Form onSubmit={handleSubmit} sx={{ width: "100%" }}>
            <Form.Item>
              <Input
                name="email"
                placeholder={t("Enter your email")}
                autoCapitalize="none"
                autoComplete="email"
                prefix={{
                  component: (
                    <Flex
                      sx={{
                        height: "18px",
                        width: "18px",
                      }}
                    >
                      <EmailSVG width="18" color="text" />
                    </Flex>
                  ),
                }}
                onChange={async (e) => {
                  await setFieldValue("email", e.currentTarget.value);
                  !touched.email && setFieldTouched("email", true, true);
                }}
                sx={{ backgroundColor: "background" }}
                value={values.email}
              />
              <ErrorMessage name="email" sx={{ mt: 1, ml: 2, color: "error.700" }} />
            </Form.Item>
            <Flex>
              <Button variant="primary" type="submit" disabled={isSubmitting || !isValid}>
                {t("Next")}
                <IconLongArrow size={4} noSvgPositioning sx={{ ml: 2 }} stroke={"white"} />
              </Button>
              {isSubmitting && <IconLoading sx={{ ml: 2 }} size="small" />}
            </Flex>
          </Form>
        )}
      </Formik>
    </PublicLayout>
  );
};
