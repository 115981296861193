import { Box, Button, Flex, IconLoading, Modal } from "@powerledger/ui-component-lib";
import { Formik } from "formik";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { object, ref, string } from "yup";

import { ErrorMessage, Form, PasswordInput } from "@/app/components/form";
import notifications from "@/app/container/notifications";
import { useDemoData } from "@/app/hooks/use-demo-data";
import { useEditUserPasswordMutation } from "@/app/types/generated/graphql";

export const ResetPasswordModal = ({ visible, closeModal }: { visible: boolean; closeModal: () => void }) => {
  const { t } = useTranslation();
  const isUsingDemoData = useDemoData();
  const [editPassword] = useEditUserPasswordMutation();

  const cleanUpModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <Modal
      visible={visible}
      maskClosable
      onCancel={cleanUpModal}
      sx={{
        maxHeight: "inherit",
      }}
      title={t("Reset Password")}
    >
      <Formik
        initialValues={{
          oldPassword: "",
          password: "",
          confirmPassword: "",
        }}
        validationSchema={object().shape({
          oldPassword: string().required(t("Required")),
          password: string()
            .required(t("Required"))
            .min(12, t("Password must contain at least 12 characters"))
            .matches(/^.*[A-Z]+.*$/, t("Password must include at least one uppercase letter"))
            .matches(/^.*[a-z]+.*$/, t("Password must include at least one lowercase letter"))
            .matches(/^.*[0-9]+.*$/, t("Password must include at least one number"))
            .matches(/^.*[?=.*?[\]#?!@$%^&*-]+.*$/, t("Password must include at least one special character")),
          confirmPassword: string()
            .required("Required")
            .oneOf([ref("password")], "Passwords don't match"),
        })}
        onSubmit={async ({ oldPassword, password }) => {
          try {
            await (isUsingDemoData
              ? Promise.resolve()
              : editPassword({ variables: { oldPassword, newPassword: password } }));
            cleanUpModal();
            notifications.success({ description: t("New password has been set successfully.") });
          } catch (error: unknown) {
            if (error instanceof Error) {
              notifications.error({
                description: t(error?.message),
              });
            }
          }
        }}
        validateOnMount
      >
        {({ handleSubmit, isSubmitting, touched, setFieldTouched, setFieldValue, isValid }) => (
          <Form onSubmit={handleSubmit} sx={{ height: "100%" }}>
            <Box sx={{ width: "50%", pr: 2 }}>
              <Form.Item sx={{ flex: 1 }}>
                <Form.Item sx={{ flex: 1, mr: 2 }}>
                  <PasswordInput
                    name="oldPassword"
                    onBlur={() => {
                      setFieldTouched("oldPassword", true, true);
                    }}
                    placeholder={t("Current Password")}
                    hasErrorIndicator={false}
                    onInput={async (e) => {
                      await setFieldValue("oldPassword", e.currentTarget.value);
                    }}
                    autoComplete="current-password"
                  />
                  <ErrorMessage name="oldPassword" sx={{ mt: 1, ml: 2 }} />
                </Form.Item>
              </Form.Item>
            </Box>
            <Flex>
              <Form.Item sx={{ flex: 1, mr: 2 }}>
                <Form.Item sx={{ flex: 1, mr: 2 }}>
                  <PasswordInput
                    name="password"
                    placeholder={t("New Password")}
                    hasErrorIndicator={false}
                    onBlur={() => {
                      setFieldTouched("password", true, true);
                    }}
                    onInput={async (e) => {
                      await setFieldValue("password", e.currentTarget.value);
                    }}
                    autoComplete="new-password"
                  />
                  <ErrorMessage name="password" sx={{ mt: 1, ml: 2 }} />
                </Form.Item>
              </Form.Item>
              <Form.Item sx={{ flex: 1, ml: 2 }}>
                <Form.Item sx={{ flex: 1, mr: 2 }}>
                  <PasswordInput
                    name="confirmPassword"
                    placeholder={t("Repeat New Password")}
                    hasErrorIndicator={false}
                    onInput={async (e) => {
                      await setFieldValue("confirmPassword", e.currentTarget.value);
                      !touched?.confirmPassword && setFieldTouched("confirmPassword", true, true);
                    }}
                    autoComplete="new-password"
                  />
                  <ErrorMessage name="confirmPassword" sx={{ mt: 1, ml: 2 }} />
                </Form.Item>
              </Form.Item>
            </Flex>
            <Flex sx={{ justifyContent: "flex-end", mt: 4 }}>
              <Button type="submit" variant="primary" disabled={isSubmitting || !isValid}>
                {t("Proceed")}
              </Button>
              {isSubmitting && <IconLoading size="small" sx={{ ml: 2 }} />}
            </Flex>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ResetPasswordModal;
