import { Flex, ThemeUIStyleObject } from "@powerledger/ui-component-lib";

// Encloses the children within the table's border area above the header
export const TableCardWrapper = ({ children, sx }: { children: React.ReactNode; sx?: ThemeUIStyleObject }) => {
  return (
    <Flex
      sx={{
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        background: "lightActive",
        px: 3,
        py: 2,
        mb: -1,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderWidth: "0.5px",
        borderStyle: "solid",
        borderColor: "shadow",
        zIndex: 1,
        position: "relative",
        borderBottomWidth: 0,
        ...sx,
      }}
    >
      {children}
    </Flex>
  );
};
