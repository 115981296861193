import { lazy, useCallback, useMemo, useState } from "react";

import { AddEditNewUserModal } from "../components/add-edit-new-user-modal/add-edit-new-user-modal";
import { AddEditNewUserModalCustomProps } from "../components/add-edit-new-user-modal/add-edit-new-user-modal.types";
import { ConfirmRemoveUserModal } from "../components/confirm-remove-user-modal.tsx/confirm-remove-user-modal";
import { ConfirmRemoveUserModalCustomProps } from "../components/confirm-remove-user-modal.tsx/confirm-remove-user-modal.types";
import ConfirmationModal from "../components/confirmation-modal/confirmation-modal";
import { ConfirmationModalCustomProps } from "../components/confirmation-modal/confirmation-modal.types";
import { CreateCounterOfferModal } from "../components/create-counter-offer-modal";
import { CreateCounterOfferModalCustomProps } from "../components/create-counter-offer-modal/create-counter-offer-modal.types";
import {
  ForwardTradeRecsAssignCustomProps,
  ForwardTradeRecsAssignModal,
} from "../components/forward-trade-recs-assign-modal";
import { ForwardTradeWithdrawModal } from "../components/forward-trade-withdraw-modal";
import { ForwardTradeWithdrawCustomProps } from "../components/forward-trade-withdraw-modal/forward-trade-withdraw-modal.types";
import { ContactSupportModal } from "../components/layout/contact-support-modal";
import { RegistryModal } from "../components/layout/registry-modal/registry-modal";
import { OnboardingCompletedModal } from "../components/onboarding-completed-modal";
import { AcceptForwardTradeOrderModal } from "../components/order-modal/accept-forward-trade-order-modal";
import { BuyOrderModal } from "../components/order-modal/buy-order-modal";
import { ForwardTradeOrderModal } from "../components/order-modal/forward-trade-order-modal";
import { StackedFilterState } from "../components/page-header/filter/filter.types";
import { ExportRequestDetailsModalCustomProps } from "../components/rec-actions-details-modal/export-request-details-modal/export-history-details-modal.types";
import ExportRequestDetailsModal from "../components/rec-actions-details-modal/export-request-details-modal/export-request-details-modal";
import { RetirementRequestDetailsModalCustomProps } from "../components/rec-actions-details-modal/retirement-request-details-modal/retirement-history-details-modal.types";
import { ForwardMarketTradeContract } from "../pages/markets/components/forward-trade-market-table/forward-trade-market-table.types";
import { ModalContextProvider } from "./modal-context";
import { ModalEnums, ModalState } from "./modal-provider.types";

const RetirementRequestDetailsModal = lazy(
  () =>
    import("../components/rec-actions-details-modal/retirement-request-details-modal/retirement-request-details-modal"),
);
export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [modalState, setModalState] = useState<ModalState | null>(null);

  const renderModals = useMemo(() => {
    switch (modalState?.type) {
      case ModalEnums.ExportRequestDetailsModal:
        return (
          <ExportRequestDetailsModal
            visible={modalState?.type === ModalEnums.ExportRequestDetailsModal}
            closeModal={() => setModalState(null)}
            {...modalState.customProps}
          />
        );
      case ModalEnums.RetirementRequestDetailsModal:
        return (
          <RetirementRequestDetailsModal
            visible={modalState?.type === ModalEnums.RetirementRequestDetailsModal}
            closeModal={() => setModalState(null)}
            {...modalState.customProps}
          />
        );

      case ModalEnums.ConfirmRemoveUserModal:
        return (
          <ConfirmRemoveUserModal
            visible={modalState?.type === ModalEnums.ConfirmRemoveUserModal}
            closeModal={() => setModalState(null)}
            {...modalState.customProps}
          />
        );

      case ModalEnums.AddEditNewUserModal:
        return (
          <AddEditNewUserModal
            visible={modalState?.type === ModalEnums.AddEditNewUserModal}
            closeModal={() => setModalState(null)}
            {...modalState.customProps}
          />
        );

      case ModalEnums.ContactSupportModal:
        return (
          <ContactSupportModal
            visible={modalState?.type === ModalEnums.ContactSupportModal}
            closeModal={() => setModalState(null)}
          />
        );

      case ModalEnums.RegistryModal:
        return (
          <RegistryModal
            visible={modalState?.type === ModalEnums.RegistryModal}
            closeModal={() => setModalState(null)}
          />
        );
      case ModalEnums.OnboardingCompletedModal:
        return (
          <OnboardingCompletedModal
            visible={modalState?.type === ModalEnums.OnboardingCompletedModal}
            closeModal={() => setModalState(null)}
          />
        );

      case ModalEnums.BuyOrderModal:
        return (
          <BuyOrderModal
            visible={modalState?.type === ModalEnums.BuyOrderModal}
            closeModal={() => setModalState(null)}
            {...modalState?.customProps}
          />
        );

      case ModalEnums.PlaceForwardTradeModal:
        return (
          <ForwardTradeOrderModal
            visible={modalState?.type === ModalEnums.PlaceForwardTradeModal}
            closeModal={() => setModalState(null)}
            {...modalState?.customProps}
          />
        );

      case ModalEnums.AcceptForwardTradeModal:
        return (
          <AcceptForwardTradeOrderModal
            visible={modalState?.type === ModalEnums.AcceptForwardTradeModal}
            closeModal={() => setModalState(null)}
            {...modalState?.customProps}
          />
        );

      case ModalEnums.ConfirmationModal:
        return (
          <ConfirmationModal
            visible={modalState?.type === ModalEnums.ConfirmationModal}
            closeModal={() => setModalState(null)}
            {...modalState?.customProps}
          />
        );

      case ModalEnums.CreateCounterOfferModal:
        return (
          <CreateCounterOfferModal
            visible={modalState?.type === ModalEnums.CreateCounterOfferModal}
            closeModal={() => setModalState(null)}
            {...modalState?.customProps}
          />
        );

      case ModalEnums.ForwardTradeRecsAssignModal:
        return (
          <ForwardTradeRecsAssignModal
            visible={modalState?.type === ModalEnums.ForwardTradeRecsAssignModal}
            closeModal={() => setModalState(null)}
            {...modalState?.customProps}
          />
        );

      case ModalEnums.ForwardTradeRecsWithdrawModal:
        return (
          <ForwardTradeWithdrawModal
            visible={modalState?.type === ModalEnums.ForwardTradeRecsWithdrawModal}
            closeModal={() => setModalState(null)}
            {...modalState?.customProps}
          />
        );

      default:
        return null;
    }
  }, [modalState]);

  const showBuyOrderModal = useCallback((prefilledAsset?: StackedFilterState) => {
    setModalState({
      type: ModalEnums.BuyOrderModal,
      customProps: {
        defaultAsset: prefilledAsset,
      },
    });
  }, []);

  const showPlaceForwardTradeModal = useCallback((prefilledAsset?: StackedFilterState) => {
    setModalState({
      type: ModalEnums.PlaceForwardTradeModal,
      customProps: {
        defaultAsset: prefilledAsset,
      },
    });
  }, []);

  const showAcceptForwardTradeModal = useCallback((tradeContract: ForwardMarketTradeContract) => {
    setModalState({
      type: ModalEnums.AcceptForwardTradeModal,
      customProps: {
        tradeContract,
      },
    });
  }, []);

  const showRegistryModal = useCallback(() => {
    setModalState({
      type: ModalEnums.RegistryModal,
      customProps: undefined,
    });
  }, []);

  const showOnboardingCompletedModal = useCallback(() => {
    setModalState({
      type: ModalEnums.OnboardingCompletedModal,
      customProps: undefined,
    });
  }, []);

  const showContactSupportModal = useCallback(() => {
    setModalState({
      type: ModalEnums.ContactSupportModal,
      customProps: undefined,
    });
  }, []);

  const showConfirmRemoveUserModal = useCallback((customProps: ConfirmRemoveUserModalCustomProps) => {
    setModalState({
      type: ModalEnums.ConfirmRemoveUserModal,
      customProps,
    });
  }, []);

  const showAddEditNewUserModal = useCallback((customProps: AddEditNewUserModalCustomProps) => {
    setModalState({
      type: ModalEnums.AddEditNewUserModal,
      customProps,
    });
  }, []);

  const showConfirmationModal = useCallback((customProps: ConfirmationModalCustomProps) => {
    setModalState({
      type: ModalEnums.ConfirmationModal,
      customProps,
    });
  }, []);

  const showRetirementRequestDetailsModal = useCallback((customProps: RetirementRequestDetailsModalCustomProps) => {
    setModalState({
      type: ModalEnums.RetirementRequestDetailsModal,
      customProps,
    });
  }, []);

  const showExportRequestDetailsModal = useCallback((customProps: ExportRequestDetailsModalCustomProps) => {
    setModalState({
      type: ModalEnums.ExportRequestDetailsModal,
      customProps,
    });
  }, []);

  const showCreateCounterOfferModal = useCallback((customProps: CreateCounterOfferModalCustomProps) => {
    setModalState({
      type: ModalEnums.CreateCounterOfferModal,
      customProps,
    });
  }, []);

  const showForwardTradeRecsAssignModal = useCallback((customProps: ForwardTradeRecsAssignCustomProps) => {
    setModalState({
      type: ModalEnums.ForwardTradeRecsAssignModal,
      customProps,
    });
  }, []);

  const showForwardTradeRecsWithdrawModal = useCallback((customProps: ForwardTradeWithdrawCustomProps) => {
    setModalState({
      type: ModalEnums.ForwardTradeRecsWithdrawModal,
      customProps,
    });
  }, []);

  return (
    <ModalContextProvider
      values={useMemo(
        () => ({
          showExportRequestDetailsModal,
          showRetirementRequestDetailsModal,
          showConfirmRemoveUserModal,
          showContactSupportModal,
          showBuyOrderModal,
          showPlaceForwardTradeModal,
          showAcceptForwardTradeModal,
          showAddEditNewUserModal,
          showOnboardingCompletedModal,
          showRegistryModal,
          showConfirmationModal,
          showCreateCounterOfferModal,
          showForwardTradeRecsAssignModal,
          showForwardTradeRecsWithdrawModal,
        }),
        [
          showExportRequestDetailsModal,
          showRetirementRequestDetailsModal,
          showConfirmRemoveUserModal,
          showContactSupportModal,
          showAddEditNewUserModal,
          showBuyOrderModal,
          showPlaceForwardTradeModal,
          showAcceptForwardTradeModal,
          showOnboardingCompletedModal,
          showRegistryModal,
          showConfirmationModal,
          showCreateCounterOfferModal,
          showForwardTradeRecsAssignModal,
          showForwardTradeRecsWithdrawModal,
        ],
      )}
    >
      {renderModals}
      {children}
    </ModalContextProvider>
  );
};
