import { Flex, Table } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { RefreshIconButton } from "@/app/components/refresh-icon-button";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";

import { ForwardTradeMarketTableProps } from "./forward-trade-market-table.types";

export const ForwardTradeMarketTable: FC<ForwardTradeMarketTableProps> = ({
  reloadForwardTrades,
  pageInfo,
  fetchData,
  loading,
  tableData,
  tableColumns,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Flex sx={{ alignItems: "center", mb: 2 }}>
        <Flex sx={{ ml: "auto" }}>
          <RefreshIconButton loading={loading} fetchData={reloadForwardTrades} />
        </Flex>
      </Flex>
      <Table
        loading={loading}
        showScrollArrows
        manualPagination
        columns={tableColumns}
        dataSource={tableData}
        fetchData={fetchData}
        pageInfo={pageInfo}
        translation={getTableTranslation(t)}
      />
    </>
  );
};
