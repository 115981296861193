import { Box, Card, Flex, LegacyCheckbox, Table } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { PageHeader } from "@/app/components/page-header";
import { RefreshIconButton } from "@/app/components/refresh-icon-button";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";

import { ForwardTradeDisabled } from "../../../components/forward-trade-disabled";
import { ForwardTradeHistoryViewProps } from "./forward-trade-history.types";

export const ForwardTradeHistoryView: React.FC<ForwardTradeHistoryViewProps> = ({
  forwardTradeEnabled,
  tableData,
  loading,
  fetchData,
  pageInfo,
  tableColumns,
  reloadTradeContractHistory,
  showAllAttributes,
  setShowAllAttributes,
  handleFilterChange,
}) => {
  const { t } = useTranslation();

  if (!forwardTradeEnabled) {
    return <ForwardTradeDisabled />;
  }

  return (
    <Box as="main" sx={{ m: 4 }}>
      <PageHeader
        title={t("Forward Trades History")}
        filterProps={{
          applyFilters: handleFilterChange,
          hideProjectFilter: true,
        }}
        datePickerProps={{
          datePickerKey: "selectedDate",
          datePickerName: "Trade Settlement Date",
        }}
        styles={{ headerSx: { width: "fit-content" } }}
      />
      <Flex
        sx={{
          flexDirection: "column",
          gap: 2,
          mt: 2,
        }}
      >
        <Card variant="compact">
          <Flex
            sx={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              background: "lightActive",
              px: 3,
              py: 2,
              mb: -1,
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4,
              borderWidth: "0.5px",
              borderStyle: "solid",
              borderColor: "shadow",
              zIndex: 1,
              position: "relative",
              borderBottomWidth: 0,
            }}
          >
            <LegacyCheckbox
              checked={showAllAttributes}
              sx={{
                color: "textDarker",
                fontWeight: "bold",
              }}
              label={t("Show All Attributes")}
              onChange={() => setShowAllAttributes((prev) => !prev)}
            />
            <RefreshIconButton loading={false} fetchData={reloadTradeContractHistory} />
          </Flex>
          <Flex
            sx={{
              flexDirection: "column",
              table: {
                td: {
                  whiteSpace: "unset",
                },
              },
            }}
          >
            <Table
              columns={tableColumns}
              dataSource={tableData}
              translation={getTableTranslation(t)}
              manualPagination
              pageInfo={pageInfo}
              fetchData={fetchData}
              loading={loading}
              initialState={
                showAllAttributes
                  ? {}
                  : {
                      hiddenColumns: ["createdDate"],
                    }
              }
              containerSx={{
                table: {
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderTop: 0,
                },
              }}
            />
          </Flex>
        </Card>
      </Flex>
    </Box>
  );
};
