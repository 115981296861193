import { Box, Button, Flex, IconLoading, Text } from "@powerledger/ui-component-lib";
import currency from "currency.js";
import { Formik } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import * as Yup from "yup";

import { Form } from "@/app/components/form";
import { LoadingOverlayIndicator } from "@/app/components/loading-overlay-indicator";

import { DisplayForwardOrderAttribute } from "../../display-forward-order-attributes";
import { ForwardSummaryAndAgreement, OrderLifeCycle } from "../common";
import { Confirmation } from "../confirmation";
import { ForwardTradeSummaryViewProps } from "./accept-forward-trade-order-modal.types";

export const ForwardTradeSummaryView: FC<ForwardTradeSummaryViewProps> = ({
  tradePosition,
  isPositionBid,
  tradeContract,
  tradeAttributes,
  productAttributes,
  fee,
  handleSubmit,
  currencySymbol,
  loading,
  closeModal,
  currentStep,
}) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  if (currentStep === OrderLifeCycle.Success) {
    return (
      <Confirmation
        text={t("Your forward trade order has been received.")}
        action={{
          content: t("View your order here"),
          onClick: () => {
            navigate("/orders/forward-trade");
            closeModal();
          },
        }}
      />
    );
  }

  if (loading) {
    return <LoadingOverlayIndicator />;
  }

  return (
    <Box>
      <Formik
        initialValues={{
          tncAgreed: false,
        }}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          tncAgreed: Yup.boolean().oneOf([true], t("Please agree to the terms and conditions")),
        })}
        validateOnMount
      >
        {({ handleSubmit, isSubmitting, values, isValid, setFieldValue }) => {
          return (
            <Box>
              <Flex sx={{ justifyContent: "space-between" }}>
                <Text sx={{ fontSize: 4, fontWeight: "bold" }}>{t("Forward Trade Summary")}</Text>
              </Flex>
              <Form onSubmit={handleSubmit}>
                <Flex>
                  <Flex
                    sx={{
                      flexDirection: "column",
                      width: "50%",
                      mr: 3,
                      maxHeight: "700px",
                      overflow: "auto",
                      pr: 3,
                      mt: 3,
                      gap: 5,
                    }}
                  >
                    <Flex sx={{ flexDirection: "column" }}>
                      <Text sx={{ fontSize: 3, mb: 3, fontFamily: "MintGroteskV08", fontWeight: 600 }}>
                        {t("Trade Attributes")}
                      </Text>
                      <Flex sx={{ gap: 1, flexDirection: "column" }}>
                        {tradeAttributes.map((props) => (
                          <DisplayForwardOrderAttribute key={props.label} {...props} />
                        ))}
                      </Flex>
                    </Flex>

                    <Flex sx={{ flexDirection: "column" }}>
                      <Text sx={{ fontSize: 3, mb: 3, fontFamily: "MintGroteskV08", fontWeight: 600 }}>
                        {t("Product Attributes")}
                      </Text>
                      <Flex sx={{ gap: 1, flexDirection: "column" }}>
                        {productAttributes.map((props) => (
                          <DisplayForwardOrderAttribute key={props.label} {...props} />
                        ))}
                      </Flex>
                    </Flex>
                  </Flex>
                  <ForwardSummaryAndAgreement
                    {...{
                      setFieldValue,
                      currencySymbol,
                      isPositionBid,
                      tradePosition,
                      tncAgreed: values.tncAgreed,
                      unitPrice: currency(tradeContract.unitPrice ?? 0, { separator: ",", fromCents: true }).value,
                      volume: tradeContract.volume,
                      fee,
                    }}
                    requireAgreement
                  >
                    <>
                      <Box sx={{ flexGrow: 1, flexBasis: "80px" }} />
                      <Flex sx={{ flexDirection: "row", justifyContent: "flex-end", alignItems: "flex-end" }}>
                        <Flex sx={{ gap: 2 }}>
                          <Button
                            type="button"
                            variant="secondary"
                            sx={{ px: 4 }}
                            disabled={isSubmitting}
                            onClick={closeModal}
                          >
                            {t("Cancel")}
                          </Button>
                          {isSubmitting ? (
                            <IconLoading size="small" />
                          ) : (
                            <Button type="submit" variant="primary" sx={{ px: 4 }} disabled={isSubmitting || !isValid}>
                              {t("Confirm")}
                            </Button>
                          )}
                        </Flex>
                      </Flex>
                    </>
                  </ForwardSummaryAndAgreement>
                </Flex>
              </Form>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
};
