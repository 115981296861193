import { useFeatureToggle } from "./use-feature-toggle";
import { FeatureToggleKey, UseAllFeatureToggleValues } from "./use-feature-toggle.types";

export const useAllFeatureToggleValues: UseAllFeatureToggleValues = () => {
  const { value: enabledCustodialModel, loading: loadingFeatureToggleValue1 } = useFeatureToggle({
    featureKey: FeatureToggleKey.EnableCustodialModel,
  });

  const { value: enabledAutomatedUnencumberance, loading: loadingFeatureToggleValue2 } = useFeatureToggle({
    featureKey: FeatureToggleKey.EnableAutomatedUnencumberance,
  });

  const { value: enabledCounterOffer, loading: loadingFeatureToggleValue3 } = useFeatureToggle({
    featureKey: FeatureToggleKey.EnableCounterOffer,
  });

  const { value: enabledForwardTrade, loading: loadingFeatureToggleValue4 } = useFeatureToggle({
    featureKey: FeatureToggleKey.EnableForwardTrade,
  });
  return {
    toggles: {
      [FeatureToggleKey.EnableCustodialModel]: enabledCustodialModel,
      [FeatureToggleKey.EnableAutomatedUnencumberance]: enabledAutomatedUnencumberance,
      [FeatureToggleKey.EnableCounterOffer]: enabledCounterOffer,
      [FeatureToggleKey.EnableForwardTrade]: enabledForwardTrade,
    },
    loading:
      loadingFeatureToggleValue1 ||
      loadingFeatureToggleValue2 ||
      loadingFeatureToggleValue3 ||
      loadingFeatureToggleValue4,
  };
};
