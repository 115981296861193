import currency from "currency.js";
import { format } from "date-fns";
import { FormikHelpers } from "formik";
import { find, startCase } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import notifications from "@/app/container/notifications";
import { useTransactionFee } from "@/app/hooks/use-transaction-fee";
import { AppDateFormats } from "@/app/lib/format-date";
import {
  TradeContractAction,
  TradePosition,
  useFewAccountDetailsQuery,
  useReviewTradeContractMutation,
  useTenantsQuery,
} from "@/app/types/generated/graphql";

import { OrderLifeCycle } from "../common";
import {
  AcceptForwardTradeFormValues,
  UseAcceptForwardTradeOrderModal,
} from "./accept-forward-trade-order-modal.types";

export const useAcceptForwardTradeOrderModal: UseAcceptForwardTradeOrderModal = ({ closeModal, tradeContract }) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(OrderLifeCycle.Form);
  const { data: tenantsData } = useTenantsQuery();

  const { data: accountData } = useFewAccountDetailsQuery();
  const { getAppliedFee, loading } = useTransactionFee();

  const [currencySymbol] = useMemo(() => {
    const tenant = find(tenantsData?.tenants, { id: accountData?.account?.tenantId });
    return [tenant?.localisation?.currencySymbol ?? "$"];
  }, [accountData?.account?.tenantId, tenantsData]);

  const tradePosition = tradeContract?.position === TradePosition.Bid ? TradePosition.Ask : TradePosition.Bid;
  const isPositionBid = tradePosition === TradePosition.Bid;
  const fee = useMemo(() => getAppliedFee(tradePosition), [getAppliedFee, tradePosition]);

  const [acceptTradeContract] = useReviewTradeContractMutation();

  const handleSubmit = useCallback(
    async (_, actions: FormikHelpers<AcceptForwardTradeFormValues>) => {
      try {
        await acceptTradeContract({
          variables: {
            input: {
              action: TradeContractAction.Accept,
              id: tradeContract.id,
            },
          },
          refetchQueries: ["TradeContracts"],
        });
        setCurrentStep(OrderLifeCycle.Success);
      } catch (e) {
        e instanceof Error &&
          notifications.error({
            description: t(e?.message),
          });
      } finally {
        actions.setSubmitting(false);
      }
    },
    [acceptTradeContract, t, tradeContract.id],
  );

  const tradeAttributes = useMemo(
    () => [
      {
        label: "Trade Lodgement Date",
        value: format(new Date(tradeContract.createdDate), AppDateFormats.AbbreviatedMonthFormat),
      },
      {
        label: "Trade Settlement Date",
        value: format(new Date(tradeContract.tradeSettlementDate), AppDateFormats.AbbreviatedMonthFormat),
      },
      {
        label: "Type",
        value: startCase(tradeContract.position.toLowerCase()),
      },
      {
        label: "Quantity",
        value: tradeContract.volume.toString(),
      },
      {
        label: "Price per unit",
        value: currency(tradeContract.unitPrice, { fromCents: true }).format(),
      },
    ],
    [tradeContract],
  );

  const getDefaultProductAttributeValue = useCallback(
    (values?: string[] | null) => {
      if (!values || !values.length) {
        return t("Any");
      }

      return values.join(", ");
    },
    [t],
  );

  const productAttributes = useMemo(() => {
    return [
      {
        label: "Vintage",
        value: getDefaultProductAttributeValue(tradeContract.formattedAttributes.vintages),
      },
      {
        label: "Eligibility",
        value: getDefaultProductAttributeValue(
          tradeContract.formattedAttributes.certificationsAndEligibilities.flatMap(({ value }) => value),
        ),
      },
      {
        label: "Location",
        value: getDefaultProductAttributeValue(tradeContract.formattedAttributes.locations),
      },
      {
        label: "Fuel Type",
        value: getDefaultProductAttributeValue(tradeContract.formattedAttributes.fuelSources),
      },
      {
        label: "Commencement of Operations Date (COD)",
        value: tradeContract.attributes.commencementDate
          ? t(`Not older than {{cod}}`, {
              cod: format(new Date(tradeContract.attributes.commencementDate), AppDateFormats.AbbreviatedMonthFormat),
            })
          : t("Any"),
      },
    ];
  }, [getDefaultProductAttributeValue, t, tradeContract]);

  return {
    currencySymbol,
    currentStep,
    loading,
    fee,
    isPositionBid,
    tradePosition,
    tradeContract,
    tradeAttributes,
    productAttributes,
    handleSubmit,
    closeModal,
  };
};
