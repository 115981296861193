import { Flex, Text } from "@powerledger/ui-component-lib";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import { EligibilityValueFormatter } from "@/app/components/eligibility-value-formatter";
import { AppDateFormats } from "@/app/lib/format-date";
import { TradePosition } from "@/app/types/generated/graphql";

import { TableValueWrapper } from "../table-value-wrapper";
import { attributesMapper } from "./forward-trade-attributes-mapper";
import { ForwardTradeAttributesTooltipProps } from "./forward-trade-attributes-tooltip.types";

const FlexWrapper = ({ children }: { children: React.ReactNode }) => (
  <Flex sx={{ gap: 4, justifyContent: "flex-start", alignItems: "center", pb: 2, borderBottom: "1px solid gray" }}>
    {children}
  </Flex>
);

export const ForwardTradeAttributesTooltip = ({ tooltipData }: { tooltipData: ForwardTradeAttributesTooltipProps }) => {
  const { t } = useTranslation();
  const commencementDate = tooltipData.attributes?.commencementDate;
  const commencementValue = commencementDate
    ? `Not older than ${format(new Date(commencementDate), AppDateFormats.AbbreviatedMonthFormat)}`
    : "Any";
  return (
    <Flex sx={{ flexDirection: "column", gap: 2 }}>
      <FlexWrapper>
        <Text
          sx={{
            fontWeight: "bold",
            fontSize: 14,
            width: 150,
          }}
        >
          {t("Vintage")}
        </Text>
        <Flex
          sx={{
            flexWrap: "wrap",
            width: 300,
            gap: 2,
          }}
        >
          {attributesMapper(t, tooltipData.vintages ?? [])}
        </Flex>
      </FlexWrapper>
      <FlexWrapper>
        <Text
          sx={{
            fontWeight: "bold",
            fontSize: 14,
            width: 150,
          }}
        >
          {t("Eligibilities")}
        </Text>
        <Flex
          sx={{
            flexWrap: "wrap",
            width: 300,
            gap: 2,
          }}
        >
          <EligibilityValueFormatter
            certificationsAndEligibilities={tooltipData.certificationsAndEligibilities}
            /** For forward trades always display "Any" in case of attribute is not specified, regardless of Bid or Ask */
            position={TradePosition.Bid}
            sx={{
              color: "text",
            }}
            showAllEligibility
          />
        </Flex>
      </FlexWrapper>
      <FlexWrapper>
        <Text
          sx={{
            fontWeight: "bold",
            fontSize: 14,
            width: 150,
          }}
        >
          {t("Fuel Type")}
        </Text>
        <Flex sx={{ color: "text", flexWrap: "wrap", width: 300, gap: 2 }}>
          {!tooltipData.attributes.fuelSources || tooltipData.attributes.fuelSources.length < 1
            ? "Any"
            : attributesMapper(t, tooltipData.fuelSources ?? [])}
        </Flex>
      </FlexWrapper>
      <Flex sx={{ gap: 4, justifyContent: "flex-start", alignItems: "center" }}>
        <Text
          sx={{
            fontWeight: "bold",
            fontSize: 14,
            width: 150,
          }}
        >
          {t("Commencement Date (COD)")}
        </Text>
        <TableValueWrapper value={commencementValue} containerSx={{ color: "text" }} />
      </Flex>
    </Flex>
  );
};
