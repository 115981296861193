import { Box, Flex, Text } from "@powerledger/ui-component-lib";
import currency from "currency.js";
import { useTranslation } from "react-i18next";

import { getTotalMarketValue, getTotalValue, getTransactionFee } from "@/app/lib/order-helpers";
import { OrderPosition, TradePosition } from "@/app/types/generated/graphql";

export default function OrderSummary(props: {
  currencySymbol: any;
  title: string;
  unitPrice: number;
  volume: number;
  orderPosition: OrderPosition | TradePosition;
  fee: number;
}) {
  const { t } = useTranslation();

  const offerType = props.orderPosition === OrderPosition.Ask ? "Offer" : "Bid";

  return (
    <>
      <Box sx={{ my: 3, fontFamily: "MintGroteskV08", fontWeight: "bold", fontSize: 3 }}>{t(props.title)}</Box>
      <Box sx={{ fontSize: 1 }}>
        <Flex sx={{ justifyContent: "space-between", mt: 1 }}>
          <Text>{t("Product Quantity")}</Text>
          <Text>{props.volume}</Text>
        </Flex>
        <Flex sx={{ justifyContent: "space-between", mt: 1 }}>
          <Text>{t("{{offerType}} Price per Unit", { offerType })}</Text>
          <Text>{props.currencySymbol + currency(props.unitPrice)}</Text>
        </Flex>
        <Flex sx={{ justifyContent: "space-between", mt: 1 }}>
          <Text>{t(`Total {{offerType}} Order Value`, { offerType })}</Text>
          <Text>{props.currencySymbol + getTotalMarketValue(props.unitPrice, props.volume)}</Text>
        </Flex>
        <Flex
          sx={{
            mt: 1,
            justifyContent: "space-between",
            pb: 3,
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            borderBottomColor: "textDarker",
          }}
        >
          <Text>
            {t("Transaction Fee ({{value}}%)", {
              value: props.fee * 100,
            })}
          </Text>
          <Text>
            {props.unitPrice & props.volume && offerType === "Bid" ? "+" : ""}
            {props.unitPrice & props.volume && offerType === "Offer" ? "-" : ""}
            {props.currencySymbol + getTransactionFee(props.unitPrice, props.volume, props.fee)}
          </Text>
        </Flex>
        <Flex
          sx={{
            justifyContent: "space-between",
            pt: 3,
          }}
        >
          <Text>{t("Total Order Value")}</Text>
          <Text>
            {props.currencySymbol + getTotalValue(props.unitPrice, props.volume, props.orderPosition, props.fee)}
          </Text>
        </Flex>
      </Box>
    </>
  );
}
