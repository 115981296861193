import { OktaProfileFeatures, UserFeatures } from "../hooks/use-okta-profile-features";

export function grantFeatureAccess(userProfileFeatures: OktaProfileFeatures, requiredFeatureClaims?: UserFeatures[]) {
  // If no feature claims are required, then allow access
  if (!requiredFeatureClaims) {
    return true;
  }

  // If all the required feature claims are enabled in the user claims, then allow access
  if (requiredFeatureClaims?.every((featureClaims) => userProfileFeatures[featureClaims])) {
    return true;
  }

  // If any of the required feature claims are not enabled in the user claims, then deny access
  return false;
}
