import { useTranslation } from "react-i18next";
import { Box } from "theme-ui";

import { PageHeader } from "@/app/components/page-header";

import { ForwardTradeDisabled } from "../../../components/forward-trade-disabled";
import { ForwardTradeMarketTable } from "../components";
import { ForwardTradeViewProps } from "./forward-trade.types";

export const ForwardTradeView: React.FC<ForwardTradeViewProps> = ({
  forwardTradeEnabled,
  tableData,
  loading,
  reloadForwardTrades,
  fetchData,
  handleFilterChange,
  pageInfo,
  tableColumns,
}) => {
  const { t } = useTranslation();
  if (!forwardTradeEnabled) {
    return <ForwardTradeDisabled />;
  }

  return (
    <Box as="main" sx={{ m: 4 }}>
      <PageHeader
        title={t("Forward Trades Market")}
        filterProps={{
          applyFilters: handleFilterChange,
          hideProjectFilter: true,
          hideStatusFilter: true,
        }}
        styles={{
          headerSx: {
            width: "fit-content",
          },
        }}
        datePickerProps={{
          datePickerKey: "selectedDate",
          datePickerName: "Trade Settlement Date",
        }}
        actions={{
          placeForwardTradeAction: {
            hide: false,
          },
        }}
      />
      <ForwardTradeMarketTable
        fetchData={fetchData}
        reloadForwardTrades={reloadForwardTrades}
        loading={loading}
        tableData={tableData}
        pageInfo={pageInfo}
        tableColumns={tableColumns}
      />
    </Box>
  );
};
