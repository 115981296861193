import { Flex, Text, Tooltip } from "@powerledger/ui-component-lib";
import { TFunction } from "i18next";
import { startCase, toLower } from "lodash";
import { Column, Row } from "react-table";

import { EligibilityValueFormatter } from "@/app/components/eligibility-value-formatter";
import { TableValueWrapper } from "@/app/components/table-value-wrapper";
import { CertificationAndEliglibilityType } from "@/app/lib/format-attributes";
import { RECOrderAttributeOptions } from "@/app/lib/format-rec-options";
import { getFuelSourceIcon } from "@/app/lib/get-fuel-source-icon";
import { TradePosition } from "@/app/types/generated/graphql";

import { TradeContractAssetsTableData } from "../../forward-trade-recs-assign-modal.types";

export const getRecsAssignedColumns = (t: TFunction, recOptions?: RECOrderAttributeOptions) => {
  const tableColumns: Column<TradeContractAssetsTableData>[] = [
    {
      Header: t("Vintage"),
      disableSortBy: true,
      accessor: "vintages",
      Cell: ({ value }: { value: string }) => {
        return <TableValueWrapper value={value} csv title="Vintages" containerSx={{ minWidth: 80 }} />;
      },
    },
    {
      Header: t("Eligibility"),
      accessor: "certificationsAndEligibilities",
      disableSortBy: true,
      Cell: ({ value }: { value: CertificationAndEliglibilityType[] }) => {
        return <EligibilityValueFormatter certificationsAndEligibilities={value} position={TradePosition.Ask} />;
      },
    },
    {
      Header: t("Project"),
      accessor: "projects",
      Cell: ({ value }: { value: string | string[] }) => {
        const projectValue = value || value?.length ? value : "-";
        return <TableValueWrapper value={projectValue} csv title={"Projects"} containerSx={{ minWidth: 100 }} />;
      },
      disableSortBy: true,
    },
    {
      Header: t("Fuel Type"),
      accessor: (row) => row.attributes.fuelSources,
      Cell: ({ value }: { value: string[] }) => {
        return (
          <Flex sx={{ color: "textDark" }}>
            {!value || value.length < 1 ? (
              "-"
            ) : (
              <TableValueWrapper
                getIcon={getFuelSourceIcon}
                title="Fuel Sources"
                value={value}
                getToolTipText={(key: string) =>
                  recOptions?.fuelSourceOptions.filter((x) => x.value === key)[0]?.label ?? ""
                }
              />
            )}
          </Flex>
        );
      },
      disableSortBy: true,
    },
    {
      Header: t("Commencement Date (COD)"),
      id: "commencementDate",
      Cell: ({ row }: { row: Row<TradeContractAssetsTableData> }) => {
        const { commencementDate } = row.original.attributes;
        return <TableValueWrapper value={commencementDate ?? "-"} />;
      },
      disableSortBy: true,
    },
    {
      Header: t("Location"),
      accessor: "locations",
      Cell: ({ value }: { value?: string[] }) => {
        return <TableValueWrapper value={value ?? []} csv title={"Locations"} containerSx={{ minWidth: 110 }} />;
      },
      disableSortBy: true,
    },
    {
      Header: t("Status"),
      accessor: "status",
      disableSortBy: true,
      Cell: ({ value, row }: { value: string; row: Row<TradeContractAssetsTableData> }) => {
        const { statusMessage, id } = row.original;
        return (
          <Flex sx={{ color: "textDark", alignItems: "center", whiteSpace: "nowrap" }}>
            {`${t(startCase(toLower(value)))}`}
            {statusMessage && (
              <Tooltip
                id={`recs-${id}-status-tooltip`}
                content={<Text>{t(statusMessage)}</Text>}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: 16,
                  height: 16,
                  marginTop: 1,
                }}
              />
            )}
          </Flex>
        );
      },
    },
    {
      Header: t("Volume"),
      accessor: "volume",
      disableSortBy: true,
    },
  ];
  return tableColumns;
};
