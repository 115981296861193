import { AddEditNewUserModalCustomProps } from "../components/add-edit-new-user-modal/add-edit-new-user-modal.types";
import { ConfirmRemoveUserModalCustomProps } from "../components/confirm-remove-user-modal.tsx/confirm-remove-user-modal.types";
import { ConfirmationModalCustomProps } from "../components/confirmation-modal/confirmation-modal.types";
import { CreateCounterOfferModalCustomProps } from "../components/create-counter-offer-modal/create-counter-offer-modal.types";
import { ForwardTradeRecsAssignCustomProps } from "../components/forward-trade-recs-assign-modal";
import { ForwardTradeWithdrawCustomProps } from "../components/forward-trade-withdraw-modal/forward-trade-withdraw-modal.types";
import { StackedFilterState } from "../components/page-header/filter/filter.types";
import { ExportRequestDetailsModalCustomProps } from "../components/rec-actions-details-modal/export-request-details-modal/export-history-details-modal.types";
import { RetirementRequestDetailsModalCustomProps } from "../components/rec-actions-details-modal/retirement-request-details-modal/retirement-history-details-modal.types";
import { ForwardMarketTradeContract } from "../pages/markets/components/forward-trade-market-table/forward-trade-market-table.types";

export interface ModalHandler {
  showBuyOrderModal: (prefilledAsset?: StackedFilterState) => void;
  showPlaceForwardTradeModal: (prefilledAsset?: StackedFilterState) => void;
  showAcceptForwardTradeModal: (tradeContract: ForwardMarketTradeContract) => void;
  showRegistryModal: () => void;
  showOnboardingCompletedModal: () => void;
  showContactSupportModal: () => void;
  showAddEditNewUserModal: (args: AddEditNewUserModalCustomProps) => void;
  showConfirmRemoveUserModal: (args: ConfirmRemoveUserModalCustomProps) => void;
  showConfirmationModal: (args: ConfirmationModalCustomProps) => void;
  showRetirementRequestDetailsModal: (args: RetirementRequestDetailsModalCustomProps) => void;
  showExportRequestDetailsModal: (args: ExportRequestDetailsModalCustomProps) => void;
  showCreateCounterOfferModal: (args: CreateCounterOfferModalCustomProps) => void;
  showForwardTradeRecsAssignModal: (args: ForwardTradeRecsAssignCustomProps) => void;
  showForwardTradeRecsWithdrawModal: (args: ForwardTradeWithdrawCustomProps) => void;
}

export enum ModalEnums {
  ContactSupportModal = "ContactSupportModal",
  OrderModal = "OrderModal",
  RegistryModal = "RegistryModal",
  OnboardingCompletedModal = "OnboardingCompletedModal",
  BuyOrderModal = "BuyOrderModal",
  PlaceForwardTradeModal = "PlaceForwardTradeModal",
  AcceptForwardTradeModal = "AcceptForwardTradeModal",
  AddEditNewUserModal = "AddEditNewUserModal",
  ConfirmRemoveUserModal = "ConfirmRemoveUserModal",
  BuyOrderModalOld = "BuyOrderModalOld",
  ConfirmationModal = "ConfirmationModal",
  RetirementRequestDetailsModal = "RetirementRequestDetailsModal",
  ExportRequestDetailsModal = "ExportRequestDetailsModal",
  CreateCounterOfferModal = "CreateCounterOfferModal",
  ForwardTradeRecsAssignModal = "ForwardTradeRecsAssignModal",
  ForwardTradeRecsWithdrawModal = "ForwardTradeRecsWithdrawModal",
}

export type ModalState =
  | {
      type: ModalEnums.ContactSupportModal | ModalEnums.OnboardingCompletedModal | ModalEnums.RegistryModal;
      customProps: undefined;
    }
  | {
      type: ModalEnums.BuyOrderModal;
      customProps: {
        defaultAsset?: StackedFilterState;
      };
    }
  | {
      type: ModalEnums.PlaceForwardTradeModal;
      customProps: {
        defaultAsset?: StackedFilterState;
      };
    }
  | {
      type: ModalEnums.AcceptForwardTradeModal;
      customProps: {
        tradeContract: ForwardMarketTradeContract;
      };
    }
  | {
      type: ModalEnums.ConfirmRemoveUserModal;
      customProps: ConfirmRemoveUserModalCustomProps;
    }
  | {
      type: ModalEnums.AddEditNewUserModal;
      customProps: AddEditNewUserModalCustomProps;
    }
  | {
      type: ModalEnums.ConfirmationModal;
      customProps: ConfirmationModalCustomProps;
    }
  | {
      type: ModalEnums.RetirementRequestDetailsModal;
      customProps: RetirementRequestDetailsModalCustomProps;
    }
  | {
      type: ModalEnums.ExportRequestDetailsModal;
      customProps: ExportRequestDetailsModalCustomProps;
    }
  | {
      type: ModalEnums.CreateCounterOfferModal;
      customProps: CreateCounterOfferModalCustomProps;
    }
  | {
      type: ModalEnums.ForwardTradeRecsAssignModal;
      customProps: ForwardTradeRecsAssignCustomProps;
    }
  | {
      type: ModalEnums.ForwardTradeRecsWithdrawModal;
      customProps: ForwardTradeWithdrawCustomProps;
    };

export type FormModalProps<T, S> = {
  initialValues?: T;
  onSave: (args: S) => void;
};
