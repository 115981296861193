import { Button, Flex, IconLoading, Modal } from "@powerledger/ui-component-lib";
import currency from "currency.js";
import { useTranslation } from "react-i18next";

import { getTotalValue, getTransactionFee } from "@/app/lib/order-helpers";
import { OfferAction, OrderPosition } from "@/app/types/generated/graphql";

import { SeparatorLayout } from "../separator-layout";
import { CounterReviewModalProps } from "./counter-review-modal.types";

export const CounterReviewModal = ({
  visible,
  setShowOfferReviewModal,
  title,
  onReviewConfirm,
  loading,
  subtitle,
  activeReviewOffer,
}: CounterReviewModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      onCancel={() => setShowOfferReviewModal(false)}
      maskClosable
      hideCloseButton
      sx={{ maxHeight: "inherit" }}
      title={t(title)}
      subtitle={t(subtitle)}
    >
      {activeReviewOffer?.actionType === OfferAction.Accept && (
        <>
          <SeparatorLayout
            wrapperStyle={{
              width: "100%",
            }}
            contentStyle={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "20%",
            }}
            data={{
              "Counter Volume": activeReviewOffer.volume,
              "Counter Price": currency(activeReviewOffer.unitPrice).format(),
              [t("Transaction Fee ({{value}}%)", { value: activeReviewOffer.fee * 100 })]: getTransactionFee(
                activeReviewOffer.unitPrice,
                activeReviewOffer.volume,
                activeReviewOffer.fee,
              ).format(),
              [t("Total Counter Price")]: getTotalValue(
                activeReviewOffer.unitPrice,
                activeReviewOffer.volume,
                OrderPosition.Bid,
                activeReviewOffer.fee,
              ).format(),
            }}
          />
        </>
      )}
      <Flex sx={{ justifyContent: "flex-end", mt: 4 }}>
        <Button
          variant="error"
          onClick={() => setShowOfferReviewModal(false)}
          sx={{ mr: 2, minWidth: 80 }}
          disabled={loading}
        >
          {t("Cancel")}
        </Button>
        <Button variant="primary" onClick={onReviewConfirm} disabled={loading}>
          {t("Confirm")}
        </Button>
        {loading && <IconLoading size="small" sx={{ ml: 2 }} />}
      </Flex>
    </Modal>
  );
};
