import { Box, Button, Flex, IconLoading, LegacySelect, Text } from "@powerledger/ui-component-lib";
import currency from "currency.js";
import { endOfDay } from "date-fns";
import { Formik } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import * as Yup from "yup";

import { LocalDatePicker, popperModifierProps } from "@/app/components/date-picker";
import { Form, FormFieldLabel } from "@/app/components/form";
import { LoadingOverlayIndicator } from "@/app/components/loading-overlay-indicator";
import { AppDateFormats, formatDate } from "@/app/lib/format-date";
import { getSelectTranslation } from "@/app/lib/get-translations-for-components";
import { getTotalValue, isWithinLimit } from "@/app/lib/order-helpers";
import { OrderExpiry, OrderPosition } from "@/app/types/generated/graphql";

import { RecFormFilter } from "../../rec-form-filter";
import { OrderLifeCycle, VolumeAndPriceInputFields } from "../common";
import { Confirmation } from "../confirmation";
import { getMinAndMaxTimeForCustomOrderExpiry, isOrderExpiryNotApplicable, utcEndOfDay } from "../helpers";
import BuyOrderSummary from "../order-summary";
import { BuyFormViewProps } from "./buy-order-modal.types";

export const BuyForm: FC<BuyFormViewProps> = ({
  filters,
  stackedValues,
  removeValue,
  formikInitialValue,
  handleSubmit,
  buyFormOptions,
  currencySymbol,
  overLimitError,
  setOverLimitError,
  loading,
  recOptionsLoading,
  closeModal,
  currentStep,
  isFilterValid,
}) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  if (currentStep === OrderLifeCycle.Success) {
    return (
      <Confirmation
        text={t(
          "Your order has been received - pending a successful balance check your order will then be available for matching.",
        )}
        action={{
          content: t("View your order here"),
          onClick: () => {
            navigate("/orders?type=Bid");
            closeModal();
          },
        }}
      />
    );
  }

  if (loading) {
    return <LoadingOverlayIndicator />;
  }

  return (
    <Box>
      <Formik
        initialValues={formikInitialValue}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          unitPrice: Yup.string()
            .required(t("Enter valid amount"))
            .test("positive-number", t("Please enter a positive number"), (value = "0") => currency(value).value > 0),
          volume: Yup.number()
            .required(t("Enter valid amount"))
            .positive(t("Enter valid amount"))
            .test("whole-number", t("Enter valid amount"), (value = 0) => Number.isInteger(value)),
          orderType: Yup.string().required(),
        })}
        validateOnMount
      >
        {({ handleSubmit, setFieldValue, isSubmitting, values, isValid, setFieldTouched }) => {
          const multiOptionValues = {
            orderType: buyFormOptions.orderTypes.find((o) => o.value === values.orderType),
            orderExpiry: buyFormOptions.orderExpiries.find((o) => o.value === values.orderExpiry),
          };

          const [expiryMinDate, expiryMaxDate] = getMinAndMaxTimeForCustomOrderExpiry(
            values.expiryDate || endOfDay(new Date()).toString(),
          );

          const isOrderExpiryNull = isOrderExpiryNotApplicable(values.orderType);

          return (
            <Form onSubmit={handleSubmit}>
              <Box>
                <Flex>
                  <Flex
                    sx={{
                      justifyContent: "space-between",
                      flexDirection: "column",
                      width: "50%",
                      mr: 3,
                      maxHeight: "680px",
                      overflow: "auto",
                      pr: 3,
                    }}
                  >
                    <Text sx={{ fontSize: 4, mb: 3 }}>{t("Product Attributes")}</Text>
                    {filters.map((filter) => (
                      <RecFormFilter
                        key={filter.name}
                        filter={filter}
                        removeValue={removeValue}
                        stackedValues={stackedValues}
                        disabled={recOptionsLoading}
                      />
                    ))}
                    <VolumeAndPriceInputFields currencySymbol={currencySymbol} />
                  </Flex>
                  <Flex
                    sx={{
                      flexDirection: "column",
                      width: "50%",
                    }}
                  >
                    <Text sx={{ fontSize: 4 }}>{t("Order Attributes")}</Text>
                    <Text sx={{ fontSize: 0, mb: 3 }}>
                      {t(
                        "Irrespective of the order type and expiration, the user retains the freedom to manually withdraw their order at any time.",
                      )}
                    </Text>
                    <Form.Item
                      sx={{
                        gap: 2,
                      }}
                    >
                      <FormFieldLabel small name="orderType" label={t("Order Type")}>
                        <LegacySelect
                          translation={getSelectTranslation(t)}
                          isLoading={recOptionsLoading}
                          value={multiOptionValues.orderType}
                          disabled={recOptionsLoading}
                          options={buyFormOptions.orderTypes}
                          onChange={async (option) => {
                            await setFieldValue("orderType", option?.value);
                            setFieldTouched("orderType", true, true);

                            if (isOrderExpiryNotApplicable(option?.value)) {
                              setFieldValue("orderExpiry", "");
                              setFieldValue("expiryDate", null);
                            } else if (!values.orderExpiry) {
                              setFieldValue("orderExpiry", OrderExpiry.Gtc);
                            }
                          }}
                        />
                      </FormFieldLabel>
                    </Form.Item>

                    <Form.Item
                      sx={{
                        gap: 2,
                      }}
                    >
                      <FormFieldLabel
                        small
                        name="orderExpiry"
                        label={t("Order Expiry")}
                        info={t("GTD expiry time is based on end of day - {{date}} UTC", {
                          date: formatDate(utcEndOfDay, {
                            noOffset: true,
                            noTZ: true,
                            formatStyle: AppDateFormats.UniversalDateTimeFormat,
                          }),
                        })}
                      >
                        <LegacySelect
                          translation={getSelectTranslation(t)}
                          isLoading={recOptionsLoading}
                          value={multiOptionValues.orderExpiry}
                          disabled={recOptionsLoading || isOrderExpiryNull}
                          options={
                            isOrderExpiryNull
                              ? buyFormOptions.orderExpiries
                              : buyFormOptions.orderExpiries.filter((option) => option.value)
                          }
                          onChange={async (option) => {
                            await setFieldValue("orderExpiry", option?.value);
                            setFieldTouched("orderExpiry", true, true);
                            if (option?.value === OrderExpiry.Custom) {
                              setFieldValue("expiryDate", endOfDay(new Date()).toString());
                            }
                          }}
                        />
                      </FormFieldLabel>
                      {values.orderExpiry === OrderExpiry["Custom"] && (
                        <LocalDatePicker
                          disabled={recOptionsLoading || isOrderExpiryNull}
                          showTimeSelect
                          timeIntervals={1}
                          minTime={expiryMinDate}
                          maxTime={expiryMaxDate}
                          minDate={new Date()}
                          dateFormat={AppDateFormats.UniversalDateTimeFormat}
                          shouldCloseOnSelect={false}
                          onChange={(date) => {
                            setFieldValue("expiryDate", date);
                          }}
                          value={values.expiryDate || ""}
                          {...popperModifierProps}
                        />
                      )}
                    </Form.Item>

                    <Flex sx={{ flexDirection: "column", fontVariantNumeric: "tabular-nums" }}>
                      <BuyOrderSummary
                        currencySymbol={currencySymbol}
                        title={"Your Bid Order"}
                        unitPrice={values.unitPrice}
                        volume={values.volume}
                        orderPosition={OrderPosition.Bid}
                        fee={values.fee}
                      />
                    </Flex>
                    <Flex sx={{ pt: 1 }}>
                      <Text
                        sx={{
                          color: "textDark",
                          fontSize: "0.6em",
                        }}
                      >
                        {t("(This amount will be debited from your linked bank account)")}
                      </Text>
                    </Flex>
                    {isValid && overLimitError && (
                      <Flex sx={{ pt: 2 }}>
                        <Text sx={{ fontSize: 0, color: "error.500" }}>{t(overLimitError)}</Text>
                      </Flex>
                    )}
                    <Flex
                      sx={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        mt: 2,
                        fontFamily: "MintGroteskV08",
                      }}
                    >
                      <Button
                        type="submit"
                        variant="primary"
                        sx={{ px: 4 }}
                        disabled={
                          isSubmitting ||
                          !isValid ||
                          !isFilterValid ||
                          !isWithinLimit(
                            getTotalValue(values.unitPrice, values.volume, OrderPosition.Bid, values.fee),
                            setOverLimitError,
                          )
                        }
                      >
                        {t("Place Order")}
                      </Button>
                      {isSubmitting && <IconLoading sx={{ ml: 2 }} size="small" />}
                    </Flex>
                  </Flex>
                </Flex>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
