import { Text } from "@powerledger/ui-component-lib";
import { TFunction } from "i18next";

export const attributesMapper = (t: TFunction, attributes: string[] | "-" | "Any") => {
  if (Array.isArray(attributes)) {
    return attributes.map((value, idx) => (
      <Text key={value}>
        {t(value)}
        {attributes.length > 1 && idx < attributes.length - 1 ? "," : ""}
      </Text>
    ));
  } else {
    return (
      <Text
        sx={{
          color: "text",
          lineHeight: 2,
        }}
      >
        {t(attributes.toLocaleString())}
      </Text>
    );
  }
};
