import { LegacyCheckbox } from "@powerledger/ui-component-lib";
import { Field } from "formik";
import { useTranslation } from "react-i18next";
import { Button, Flex, Text } from "theme-ui";

import { TradePosition } from "@/app/types/generated/graphql";

import { AppCleaveTypes, CleaveInput } from "../../cleave-input";
import { Form, FormFieldLabel } from "../../form";
import ForwardTradeOrderSummary from "../order-summary";

export const VolumeAndPriceInputFields = ({
  currencySymbol,
  isPositionBid = true,
}: {
  currencySymbol: string;
  isPositionBid?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Flex
      sx={{
        flexDirection: "row",
        gap: 2,
        justifyContent: "space-between",
      }}
    >
      <Form.Item sx={{ flex: 1 }}>
        <FormFieldLabel small name="volume" label={t("Product Quantity *")}>
          <Field
            name="volume"
            sx={{
              input: { fontSize: 3 },
            }}
            component={CleaveInput}
            type={AppCleaveTypes.Quantity}
            variant="input"
          />
        </FormFieldLabel>
      </Form.Item>
      <Form.Item sx={{ flex: 1 }}>
        <FormFieldLabel small name="unitPrice" label={t(`${isPositionBid ? "Bid" : "Offer"} Price per Unit *`)}>
          <Field
            name="unitPrice"
            prefix={currencySymbol}
            sx={{
              input: { fontSize: 3 },
            }}
            component={CleaveInput}
            type={AppCleaveTypes.Amount}
            variant="input"
          />
        </FormFieldLabel>
      </Form.Item>
    </Flex>
  );
};

export const ForwardSummaryAndAgreement: React.FC<{
  currencySymbol: string;
  isPositionBid: boolean;
  tradePosition: TradePosition;
  unitPrice: number;
  volume: number;
  fee: number;
  tncAgreed?: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  requireAgreement?: boolean;
}> = ({
  currencySymbol,
  isPositionBid,
  tradePosition,
  unitPrice,
  volume,
  fee,
  tncAgreed,
  children,
  requireAgreement,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  return (
    <Flex
      sx={{
        flexDirection: "column",
        width: "50%",
        height: "100%",
        backgroundColor: "white",
        padding: 3,
        borderRadius: 4,
        mt: 3,
      }}
    >
      <Flex sx={{ flexDirection: "column" }}>
        <Flex sx={{ flexDirection: "column", fontVariantNumeric: "tabular-nums" }}>
          <ForwardTradeOrderSummary
            currencySymbol={currencySymbol}
            title={`Your ${isPositionBid ? "Purchase" : "Sell"} Order`}
            unitPrice={unitPrice}
            volume={volume}
            orderPosition={tradePosition}
            fee={fee}
          />
        </Flex>

        {requireAgreement ? (
          <Flex sx={{ mt: 4, flexDirection: "column", fontFamily: "MintGroteskV08", gap: 3 }}>
            <Flex sx={{ flexDirection: "column", gap: 2 }}>
              <Text sx={{ fontWeight: "bold" }}>{t("Forward Trade Agreement")}</Text>
              <Text sx={{ fontSize: 12 }}>
                {t(
                  "The legal contract outlines the rights and obligations of all parties involved and serves as a legally binding agreement.",
                )}
              </Text>
              <Button type="button" variant="secondary" sx={{ width: "fit-content" }}>
                {t("View Agreement")}
              </Button>
            </Flex>

            <Form.Item
              sx={{
                gap: 3,
                flexDirection: "row",
              }}
            >
              <LegacyCheckbox
                checked={tncAgreed}
                onChange={() => {
                  setFieldValue("tncAgreed", !tncAgreed);
                }}
              />
              <Text sx={{ fontFamily: "MintGroteskV08", fontSize: 12 }}>
                {t(
                  "I have read and agree to the terms and conditions. I understand and acknowledge that I am entering into a legally binding contract.",
                )}
              </Text>
            </Form.Item>
          </Flex>
        ) : (
          <></>
        )}
      </Flex>

      {children}
    </Flex>
  );
};

export * from "./common.types";
