import { UserClaims } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { useMemo } from "react";

import { CustomUserClaims } from "@/app/types/okta.types";

import { UseOktaProfileFeatures } from "./use-okta-profile-features.types";

export const useOktaProfileFeatures: UseOktaProfileFeatures = () => {
  const okta = useOktaAuth();
  const oktaProfileFeatures = useMemo(() => {
    const { forwardTradeEnabled } =
      (okta.oktaAuth?.authStateManager.getAuthState()?.accessToken?.claims as UserClaims<CustomUserClaims>) ?? {};

    return { forwardTradeEnabled: !!forwardTradeEnabled };
  }, [okta]);

  return { oktaProfileFeatures };
};
