import { Text, Tooltip } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";
export const HeaderWithToolTip = ({ titleText, tooltipText }: { titleText: string; tooltipText: string }) => {
  const { t } = useTranslation();
  return (
    <>
      <Text>{t(titleText)}</Text>
      <Tooltip
        id={titleText}
        content={
          <Text
            sx={{
              lineHeight: 2,
            }}
          >
            {t(tooltipText)}
          </Text>
        }
        sx={{
          svg: {
            width: 18,
            height: 18,
            path: {
              fill: "primary.500",
            },
          },
        }}
      />
    </>
  );
};
