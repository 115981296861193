import { Flex, Text } from "@powerledger/ui-component-lib";

export const DisplayForwardOrderAttribute = ({ label, value }: { label: string; value: string }) => {
  return (
    <Flex sx={{ flexDirection: "row", alignItems: "center", gap: 3 }}>
      <Text sx={{ minWidth: 180, maxWidth: 180, fontWeight: 500 }}>{label}</Text>
      <Text>{value}</Text>
    </Flex>
  );
};
